import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';

import NavSpacer from './components/NavSpacer';
import NavOverlay from './components/NavOverlay';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';

class SideNav extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}		
	}
	
	componentDidMount(){
		
		 var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
			$("#layoutSidenav_nav .sb-sidenav a.nav-link").each(function() {
				if (this.href === path) {
					$(this).addClass("active");
				}
			});

		// Toggle the side navigation
		$("#sidebarToggle").on("click", function(e) {
			e.preventDefault();
			$("body").toggleClass("sb-sidenav-toggled");
		});
		
	}
	
	render(){
		
		const navItems = items => {
		  return items.map((item, index) => itemType(item, index));
		};

		const itemType = (item, index) => {
		  if (item.children) {
			  if(item.allowed){
				  if(item.allowed.indexOf(this.props.role)>-1)
					return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} role={this.props.role} />;
			  }
			  else {
				return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} role={this.props.role} />;
			  }

		  } else if (item.divider) {
			return <NavDivider key={index} />;
		  } else {
			  if(item.allowed)
			  {
				  if(item.allowed.indexOf(this.props.role)>-1)
					return <NavSingleItem item={item} key={index} />;
			  }
			  else 
				  return <NavSingleItem item={item} key={index} />;
		  }
		};


		return(
			<div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
						{/*<div className="sb-sidenav-menu-heading">Core</div>*/}
						                           							
							 {navItems(this.props.nav.top)}
                            
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Logged in as:</div>
                        {getls('user', 'name')}
                    </div>
                </nav>
            </div>
		);
	}
	
}
export default SideNav;
