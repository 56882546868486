import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, momentDate} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {LineLoader} from '../../elements';

import StaticData from './StaticData';
import NO_IMAGE from '../../../assets/img/noimage-square.png';

class Events extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadHistoryData();
	}
	
	loadHistoryData(){
		try{
			this.setState({showLoader: true});
			API.get('v1/site/upcomingevents').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){}
	}
	
	render(){

		return(
			<div className="news-list-block">
				
				{Object.keys(this.state.dataList).length<=0 ?
				<Card>
					<Card.Body>
					<h5>Sorry no upcoming events..!</h5>
					</Card.Body>
				</Card>
				:
				<>
				{this.state.dataList.map((item,i)=>{
					return <div className="news-list-item" key={i} > 
						<div className="news-img">
							<Link to="/" >
							{item.featureimage=='' || item.featureimage==null || item.featureimage.length<=0 ? 
								<img src={NO_IMAGE} style={{maxHeight: '100px'}} /> : <img src={Api.ImageUrl + item.featureimage} style={{maxHeight: '100px'}}  />
							}
							</Link>
						</div>			        			
						<div className="news-content">
							<h5 className="news-title">
								<Link to="/" >{item.title}</Link>
							</h5>
							<div className="news-date">
								<i className="fa fa-calendar-check-o"></i>
								<span>{momentDate(item.eventstartdate, "MMM, DD YYYY")}</span>
							</div>
							<div className="news-desc">
								<p>
								<i className="mdi mdi-map-marker text-danger" /> {item.eventvenue}
								</p>
							</div>
						</div>			        			
					</div>;
				})
				}
				</>
				}
					
			</div>
		);
	}
	
}
export default Events;
