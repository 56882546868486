import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Pagination from "react-js-pagination";
import {getls, upperCase, momentDate} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';

import AddAnnouncement from './AddAnnouncement';
import EditAnnouncement from './EditAnnouncement';

class Announcement extends Component{
	constructor(props) {
		super(props);
		this.state = {
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			editData: [],
			dataView: [],
			currentPage: 1,
			todosPerPage: 15,
			activePage : 1,
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadAnnouncements = this.loadAnnouncements.bind(this);
		this.afterEditNews = this.afterEditNews.bind(this);
	}
	
	componentDidMount(){
		
		this.loadAnnouncements();
	}
	
	loadAnnouncements(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/webadmin/news/list/announcement/').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.title).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Page?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/webadmin/news/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	
	handleEdit(item){
		try{
			
			this.setState({editData: item, showEditModal: true});
		}
		catch(error){
			
		}
	}
	
	afterEditNews(){
		this.setState({showEditModal: false});
		this.loadAnnouncements();
	}
	
	render(){

		let sno = 1;
		
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				
				<Row>
					<Col md={9} >
						<div className="input-group" >
							<input type="text" className="form-control form-control-sm" placeholder="Search by Title..." onChange={this.handlePageSearch} />
							<div className="input-group-append">
								<span className="input-group-text"><i className="ion-android-search" /></span>
							</div>
						</div>
					</Col>
					<Col md={3} >
						<div className="text-right" >
							<ButtonGroup >
								<Button type="button" size="sm"  variant="outline-secondary" onClick={()=>{this.setState({showAddModal: true})}} >
									<span className="ion-android-add"></span> Add Announcement
								</Button>
								
								<Button variant="outline-secondary" size="sm" onClick={this.loadAnnouncements} >
									<i className="ion-ios7-reload" /> Refresh
								</Button>
							</ButtonGroup >
						</div>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						
							{this.state.showLoader ? <center><Loader type="dot" /></center> : 
								<Table hover bordered size="sm cl__table__xs" >
									<thead>
										<tr>
											<th width="80" >S.No</th>
											<th>Announcement Title</th>
											<th width="120" >Action</th>
										</tr>
									</thead>
									<tbody>
									{currentTodos.map((item,i)=>{
											return <tr key={i} >
												<td>{indexOfFirstTodo+i+1}</td>
												<td>
													{item.title} <br />
													<span style={{fontSize:'11px'}} >Created on : {momentDate(item.idate,'DD-MM-YYYY')}</span> 
												</td>
												<td align="center" >
													<Button type="button" size="sm" color="info" onClick={this.handleEdit.bind(this,item)} >
														<span className="ion-edit"></span>
													</Button>	
													&nbsp;
													<Button type="button" size="sm" variant="danger" title="Remove" onClick={this.handleRemove.bind(this,item,i)} >
														<span className="ion-close-round"></span>
													</Button>
												</td>
											</tr>;
										})
										}
									</tbody>
								</Table>
							}
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={1} >
						<select className="form-control form-control-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} style={{padding:'0px'}} >
							<option selected={this.state.todosPerPage=='15' ? 'selected' : ''}  value="15"> 15 </option>
							<option selected={this.state.todosPerPage=='25' ? 'selected' : ''}  value="25"> 25 </option>
							<option selected={this.state.todosPerPage=='50' ? 'selected' : ''}  value="50"> 50 </option>
							<option selected={this.state.todosPerPage=='100' ? 'selected' : ''}  value="100"> 100 </option>
						</select>
					</Col>
					<Col md={8}>
						<Pagination
						 activePage={this.state.activePage}
						 itemsCountPerPage={this.state.todosPerPage}
						 pageRangeDisplayed={3}
						 totalItemsCount={this.state.dataView.length}
						 onChange={(pageNumber)=>{
							this.setState({activePage: pageNumber});
							this.state.currentPage = Number(pageNumber); 
						 }}
						/>
					</Col>
				</Row>
				
				<Modal show={this.state.showAddModal} onHide={()=>{this.setState({showAddModal:false})}} backdrop="static" size="lg" className="" >
					<Modal.Header closeButton>
						Add Announcement
					</Modal.Header>
					<Modal.Body>
						<AddAnnouncement atClose={()=>{this.setState({showAddModal: false})}} atFinish={this.loadAnnouncements} />
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditModal} onHide={()=>{this.setState({showEditModal:false})}} backdrop="static" size="lg" className="" >
					<Modal.Header closeButton>
						Edit Announcement
					</Modal.Header>
					<Modal.Body>
						<EditAnnouncement atClose={()=>{this.setState({showEditModal: false})}} editData={this.state.editData} atFinish={this.afterEditNews}  />
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default Announcement;
