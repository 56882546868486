import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table, Card } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';
import NOIMAGE_WIDE from '../../../../assets/img/noimage-xxl.png';


import ContentEditor from './ContentEditor';
import HomePageMenu from './HomePageMenu';

class HomePage extends Component{
	constructor(props) {
		super(props);
		this.state = {
		  showAddSlider: false,
		  showEditSlider: false,
		  editData: [],
		  dataList: [],
		  dataView: [],
		  btnText: 'Update',
		  btnMission: 'Update',
		  selectedImage: [],
			selectedFilename: '',
			selectedUrl: '',
			showfileBrowser: false,
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			API.get('v1/webadmin/staticcontent/home_about_image').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, selectedUrl: res['data'].data.data_value, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/webadmin/staticcontent/save',$("#frm_abtSlider").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, btnText: 'Update'});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, btnText: 'Update'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'Update'});
		}
	}
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<HomePageMenu />
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				<Row>
					<Col md={7} >
						<label>Home Page About Slider Image</label>
						<hr />
							{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length > 4 ? 
								<div>
									<img src={Api.ImageUrl+this.state.selectedUrl} style={{width: '100%', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}} />
								</div>
							:
								<div>
									
									<img src={NOIMAGE_WIDE} style={{width: '100%', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}}  />
								</div>
							}
							
							<form action="" method="post" id="frm_abtSlider" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
								<input type="hidden" name="i__dataKey" value="home_about_image" />
								<input type="hidden" name="i__dataValue" value={this.state.selectedUrl} />
								<hr />
								<Button type="submit" variant="success" size="sm" disabled={this.state.btnText!='Update'} >
									{this.state.btnText}
								</Button>
							</form>
							
					</Col>
					
					<Col md={5} >
						
							<Card>
								<Card.Header>
									Our History <span style={{fontSize: '12px'}} >(This also displayed in footer history content)</span>
								</Card.Header>
								<Card.Body>
									<ContentEditor dataKey="homepage_history" />
								</Card.Body>
							</Card>
							<br />
							<Card>
								<Card.Header>
									Our Mission
								</Card.Header>
								<Card.Body>
									<ContentEditor dataKey="mission" />
								</Card.Body>
							</Card>
							<br />
							<Card>
								<Card.Header>
									Our Vission
								</Card.Header>
								<Card.Body>
									<ContentEditor dataKey="vision" />
								</Card.Body>
							</Card>
					</Col>
					
				</Row>
				
				
				<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</React.Fragment>
		);
	}
	
}
export default HomePage;
