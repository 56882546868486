import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader, NewsScroll} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';
import {CircleSlowLoader} from '../../elements';

import PageHeader from '../Page/PageHeader';

const styles = {
	imgContainer: {
		border: '2px solid #8e8b8b',
		padding: '5px',
		borderRadius: '5px',
		cursor: 'pointer',
	},
	textCss:{
		/* position: 'absolute',
		bottom: '10px',
		textAlign: 'center',
		border: '1px solid #3e423e',
		color: '#f3f700',
		background: '#3e3e37db',
		borderRadius: '3px',
		fontSize: '13px',
		padding: '0px 5px',
		fontWeight: '600', */
		display: 'inline',
		padding: '0.2em 0.6em 0.3em',
		fontSize: '75%',
		fontWeight: '600',
		lineHeight: '1',
		color: 'yellow',
		background: 'rgba(0, 0, 0, 0.65)',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		verticalAlign: 'baseline',
		borderRadius: '0.25em',
		position: 'absolute',
		bottom: '12px',
		left: '25px',
	}
}

class Gallery extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
			showLoader: true,
		}
	}
	
	componentDidMount(){
		this.loadGallery();
	}
	
	loadGallery(){
		try{
			this.setState({showLoader: true});
			API.get('v1/site/gallery/').then(res=>{
				if(res['data'].status=='1') {
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});						
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	showGalery(){
		try{
			var rv = [];
			this.state.dataList.map((item,i)=>{
				rv.push(<Col md={3} className="mt-15" >
					<div style={styles.imgContainer} >
						<Link to={"/gallery/"+ item.slug} >
							<div>
								<div className="text-center" >
									<img src={Api.ImageUrl + item.filepath} style={{width: '100%', height: '130px'}} />
									<div style={styles.textCss} >{item.gallerytitle}</div>
								</div>
							</div>
						</Link>
					</div>
				</Col>);
			});
			return rv;
		}
		catch(error){ }
	}
	
	render(){
		
		
		return(
			
			<section>
				<PageHeader pageTitle="Gallery" />
			
				<div  className="container page-in pb-50 ">
					
					{this.state.showLoader ? 
						<Row>
							<Col md={12} >
								<div className="text-center" >
									<CircleSlowLoader />
								</div>
							</Col>
						</Row> : 
						<Row>
							{this.showGalery()}
						</Row>
					}					
				</div>
			</section>
			
		);
	}
	
}
export default Gallery;
