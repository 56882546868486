import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import NOIMAGE_WIDE from '../../../../assets/img/noimage-xxl.png';
import Select from 'react-select';
import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';


const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	},
	removeBtn: {
	    position: 'absolute',
		background: 'red',
		color: '#fff',
		borderRadius: '50px',
		padding: '0px 4px',
		fontSize: '11px',
		border: '1px solid black',
	}
}

class AddStaffImage extends Component{
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Save',
			showLoader: false,
			linkList: [],
			selectedImage: [],
			selectedFilename: '',
			selectedUrl: '',
		}
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
	
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			});
			//console.log(url);
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/webadmin/staticdata/save', $("#frm_AddStaff_1").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					document.getElementById("frm_AddStaff_1").reset();
					this.setState({showLoader: false, btnSave: 'Save', selectedUrl: '', selectedFilename: '',  selectedImage: []});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Save'});
				}
			});
		}
		catch(error){
			alert('Unexpected Error');
			this.setState({showLoader: false, btnSave: 'Save'});
		}
	}
	
	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_AddStaff_1" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__dataKey" value="home_teachers_list" />
					<input type="hidden" name="i__dataValue" value={this.state.selectedUrl} />
					
					<Row>
						<Col md={4} >
							<label>Staff Image <span className="required"></span></label>
							
							{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length > 4 ? 
								<div>
									<img src={Api.ImageUrl+this.state.selectedUrl} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}} />
								</div>
							:
								<div>
									
									<img src={NOIMAGE_WIDE} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}}  />
								</div>
							}
							
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={10} >
							<label>Staff Name <span className="text-danger">*</span></label>
							<input type="text" name="i__data1" className="form-control form-control-sm"  placeholder="Staff Name" required />
						</Col>
						<Col md={2} >
							<label>Display Order<span className="text-danger"></span></label>
							<input type="number" name="i__data4" className="form-control form-control-sm"  placeholder="Order" />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>Designation<span className="text-danger">*</span></label>
							<input type="text" name="i__data2" className="form-control form-control-sm"  placeholder="Designation" required />
						</Col>
						<Col md={12} >
							<label>About Staff<span className="text-danger"></span></label>
							<textarea name="i__data3" className="form-control form-control-sm"  placeholder="About" rows="3" ></textarea>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<hr />
							<div className="float-left" >
								<label className="chk-container">Active
								  <input type="checkbox" defaultChecked={true} name="i__active" />
								  <span className="checkmark"></span>
								</label>
							</div>
							<div className="float-right" >
								<Button type="reset" variant="danger" size="sm" onClick={this.props.atClose} >
									Cancel 
								</Button>
								&nbsp;
								<Button type="submit" variant="success" size="sm" disabled={this.state.btnSave!='Save'} >
									{this.state.btnSave=='Save' ? 
									<span className="ion-checkmark"></span> : 
									<span className="ion-loading-a"></span>}
									&nbsp; {this.state.btnSave}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
				
				<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</React.Fragment>
		);
	}
	
}
export default AddStaffImage;
