import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';


class AdminHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		
	}
	
	
	

	render(){
		
		return(<></> );
		
		/* var logged = getlsItem('admin_user_logged');
		
		if(logged=='YES'){
		
			return(
				<div className="">
					
				</div>
			);
			
		}
		else{
			return(
				<></>
			);
		} */
	}
	
}
export default AdminHeader;
