import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Button} from 'react-bootstrap';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {PageLoader} from '../../elements';

class MyProfile extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader:false,
			profileList: [],
			btnText:'Update',
			passText: 'Update Password',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handlePassFormSubmit = this.handlePassFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadProfile();
	}
	
	loadProfile(){
		try{
			this.setState({showLoader:true});
			API.get('v1/webadmin/myprofile').then(res=>{
				//console.log(res);
				if (res['data'].status=='1'){
					//notify.show(res['data'].message,'success');
					this.setState({profileList:res['data'].data,showLoader:false});
						
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader:false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}		
	}
	
	getValue(fieldName){
		try{
			var d=this.state.profileList;
			return d[fieldName];	
		}
		catch(error){
			
		}
	}
	
	setValue(fieldName, e){
		try{
			var d=this.state.profileList;
			//return d[fieldName];
			d[fieldName]=e.target.value;
			this.setState({profileList:d});	
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{			
			this.setState({showLoader: true, btnText: 'Validating..'});
			API.post('v1/webadmin/myprofile/update', $("#frmMyProfile").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnText: 'Update'});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnText: 'Update'});
					
				}
			});			
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'Update'});
		}
	}
	
	
	handlePassFormSubmit(e){
		e.preventDefault();
		try{			
			this.setState({showLoader: true, passText: 'Validating..'});
			API.post('v1/webadmin/myprofile/updatepass', $("#frmUpdatePass").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmUpdatePass").reset();
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, passText: 'Update Password'});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, passText: 'Update Password'});
					
				}
			});			
		}
		catch(error){
			this.setState({showLoader: false, passText: 'Update Password'});
		}
	}
	
	render(){

		return(
			<React.Fragment>
			
				<PageLoader loading={this.state.showLoader} />
				
				<Row>
					<Col md={6} >
					
				<form action="" method="post" encType="multipart/form-data" id="frmMyProfile" onSubmit={this.handleFormSubmit}>
					
					<input type="hidden" name="i__id" value={this.getValue('id')} />
					
					<Row>
						<Col md={12} >
							<label>Username</label>
							<input type="text" className="form-control form-control-sm" requried name= "" placeholder= "Enter Your Name"  defaultValue={this.getValue('username')} readOnly />
						</Col>
					</Row>
					
					<Row className="mt-15">
						<Col md={12} >
							<label>Name</label>
							<input type="text" className="form-control form-control-sm" requried name= "i__name" placeholder= "Enter Your Name"  defaultValue={this.getValue('name')} />
						</Col>
					</Row>
					
					<Row className="mt-15">
						<Col md={12} >
							<label>Mobile</label>
							<input type="number" className="form-control form-control-sm" requried name= "i__mobile" placeholder= "Enter Your MobileNo" defaultValue={this.getValue('mobile')}  />
						</Col>
					</Row>
				
				
				
					<Row className="mt-15">
						<Col md={12} >
							<label>Email</label>
							<input type="email" className="form-control form-control-sm"  name= "i__email" placeholder= "Enter Your Email" defaultValue={this.getValue('email')} />
						</Col>
					</Row>
					
					
					
					<Row className = "mt-15">
						<Col md={12} >
							<label>Address</label>
							<textarea rows="5" className="form-control form-control-sm"  name= "i__address" placeholder= "Enter Your address" value={this.getValue('address')} onChange={this.setValue.bind(this,'address')}></textarea>
						</Col>
					</Row>
					
					
					
					<Row className = "mt-15">	
						<Col md={12} >
							<button type="submit" className="btn btn-primary btn-md" disabled={this.state.btnText!='Update'} >
							{this.state.btnText}</button>
						</Col>
					</Row>					
					
			</form>
			
			</Col>
			
			<Col md={2} ></Col>
			
			<Col md={4} >
				
				<form action="" method="post" encType="multipart/form-data" id="frmUpdatePass" onSubmit={this.handlePassFormSubmit} >
					
					<input type="hidden" name="i__id" value={this.getValue('id')} />
					
					<Row>
						<Col md={12} >
							<label>Old Password <span className="text-danger">*</span></label>
							<input type="password" name="i__oldPass" required className="form-control form-control-sm" placeholder="Old Password" />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={12} >
							<label>New Password <span className="text-danger">*</span></label>
							<input type="password" name="i__newPass" required className="form-control form-control-sm" placeholder="New  Password" />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={12} >
							<label>Confirm Password <span className="text-danger">*</span></label>
							<input type="password" name="i__confirmPass" required className="form-control form-control-sm" placeholder="Confirm  Password" />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={12} >
							<div className="text-right" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.passText!='Update Password'} >
								{this.state.passText}
								</Button>
							</div>
						</Col>
					</Row>
				
				</form>
			
			</Col>
			</Row>
			
			
			</React.Fragment>
		);
	}
	
}
export default MyProfile;


