import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button, Form} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, getlsItem, numberToMonth} from '../../../utils';
import {classList} from '../../../utils/Variables';
import LOGO from '../../../assets/img/Sri-Vaiyapuri-Vidyala-logo.png';

class Instruction extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
		}
	}

	
	render(){

		if(getlsItem('admission_user_logged')!='YES'){
			return(<Redirect to="/admission/login" />);
		}
		
		return(
			<Card>
				<Card.Body>
					<Row>
						<Col md={12} >
							<h5>Instruction
								<span className="pull-right" >
									<Link to="/admission/home" style={{fontSize: '13px'}} >
										<i className="ion-arrow-left-c" /> &nbsp; Go Back
									</Link>
								</span>
							</h5>
							<hr />
							<ul>
								<li>The fields marked with (<span className="text-danger">*</span>) is required</li>
								<li>Please upload the photos in size of 300 KB to 1 MB</li>
								<li>Before submitting the application please check all the inputs</li>
							</ul>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}
	
}
export default Instruction;
