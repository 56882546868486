import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import {LineLoader, SiteSlider} from '../../elements';

import PageHeader from './PageHeader';
import RightSidebar from './RightSidebar';

class Page extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	getField(fieldName){
		try{
			var d = this.props.data;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){

		return(
			<React.Fragment>
				
				<PageHeader pageTitle={this.getField('pagetitle')} />
				
				{(this.getField('pagetemplate')=='withsidebar') ? 
					<section>
						<div className="container py-50" >
							<Row>
								<Col md={8} >
									<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />
								</Col>
								
								<Col md={4} >
									<RightSidebar />
								</Col>
							</Row>
						</div>
					</section>
					:
					<section>
						<div className="container py-50" >
							<Row>
								<Col md={12} >
									<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />
								</Col>
							</Row>
						</div>
					</section>
				}
				
			
			</React.Fragment>
		);
	}
	
}
export default Page;
