import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import Step1 from './pages/Registration/Step1';
import OtpVerify from './pages/Registration/OtpVerify';
import NewApplication from './pages/NewApplication';
import FilledApplications from './pages/FilledApplications';


const pageList = [
	{ name: 'Login', path: '/admission', component: Login },
	{ name: 'Login', path: '/admission/login', component: Login },
	{ name: 'Forgot Password', path: '/admission/forgot-password', component: ForgotPassword },
	{ name: 'Reset Password', path: '/admission/reset-password', component: ResetPassword },
	{ name: 'Dashboard', path: '/admission/home', component: Dashboard },
	{ name: 'Registration', path: '/admission/online-registration', component: Step1 },
	{ name: 'Mobile Verification', path: '/admission/mobile-verification', component: OtpVerify },
	{ name: 'New Application', path: '/admission/new-application', component: NewApplication },
	{ name: 'Filled Applications', path: '/admission/filled-applications', component: FilledApplications },
];

export default pageList;