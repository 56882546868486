import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var, Api} from '../../../utils/Variables';


class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
	}
	
	componentDidMount(){
		 //window.addEventListener('scroll', this.handleWindowScroll);
	}
	
	handleLogOut(){
		
		if(!window.confirm('Do you want to logout?')){
			return;
		}
		setlsItem('user_logged', 'no');
		setlsItem('admin_user_logged', 'no');
		setls('logged_user', '');
		this.setState({isRedirect:true});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
	}
	
	
	getPageTitle = () => {
		let name;
		this.props.routes.map(prop => {			
		  if (prop.path === this.props.location.pathname) {
			name = prop.name;
		  }
		  return null;
		});
		return name;
	};

	render(){
		
		 if(getlsItem('user_logged')!='YES'){
			return(<Redirect to={"/"+Var.AdminPath} />);
		}
		
		
		return(
			<nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
				<Link className="navbar-brand" to="/">
					{Var.Title}
				</Link>
				<button className="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="javascript:;" style={{fontSize: '25px'}} >
					<i className="ion-navicon-round"></i>
				</button
				>
				<div className="cl__page_title" >
					{this.getPageTitle()}
				</div>
				<div className="d-none d-md-inline-block form-inline ml-auto mr-10 mr-md-3 my-2 my-md-0"  >
					<a className="btn btn-warning btn-sm" href={Api.SiteUrl} target="_blank" >Open Website</a>
				</div>
				
				{/*<form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0" onSubmit={this.handleFormSubmit} >
					<div className="input-group">
						<input className="form-control" type="text" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
						<div className="input-group-append">
							<button className="btn btn-primary" type="button"><i className="ion-android-search"></i></button>
						</div>
					</div>
				</form>*/}
				
				<ul className="navbar-nav ml-auto ml-md-0">
					<NavDropdown title={<i className="ion-android-social-user"></i>} id="basic-nav-dropdown">
					<NavDropdown.Item><Link to="/webadmin/app/myprofile">My Profile</Link></NavDropdown.Item>
					<NavDropdown.Divider />
					<NavDropdown.Item href="javascript:;" onClick={this.handleLogOut} >Log Out</NavDropdown.Item>
				  </NavDropdown>
				</ul>
			</nav>
		);
	}
	
}
export default Header;
