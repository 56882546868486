import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';


class NiCollapse extends Component{
	constructor(props){
		super(props);
		this.state = {
			showContent: false,
		}		
		
		this.handleCardClick = this.handleCardClick.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleCardClick(){
		this.setState({showContent: !this.state.showContent});
	}
	
	render(){
		
		return(
			<Card {...this.props} >
				<Card.Header onClick={this.handleCardClick} style={{cursor: 'pointer'}} >
					{this.props.title || 'Header'}
					<div className="pull-right">
						{this.state.showContent ? <i className="ion-chevron-down" /> : <i className="ion-chevron-up" />}
					</div>
				</Card.Header>
				{this.state.showContent ? <Card.Body>
					{this.props.children}
				</Card.Body> : null}
			</Card>
		);
	}
	
}
export default NiCollapse;
