import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import BarLoader from '../BarLoader';


class Menu extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: false,
		}		
		
	}
	
	getFirstLevelChild(menus){
		
		var rv = [];
		menus.map((menu,key)=>{
			
			if(menu.children && Object.keys(menu.children).length>0){
				
			}
			else{

				if(this.isNewLink(menu.menulink)){
					rv.push(<li>
						<a activeClassName="active" to={"/"+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} >{menu.title}</a>
					</li>);
				}
				else{
					rv.push(<li>
						<NavLink activeClassName="active" to={"/"+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} >{menu.title}</NavLink>
					</li>);
				}
			}
		});
		
		return rv;
	}
	
	getMenus(menus){
		
		var rv = [];
		menus.map((menu,key)=>{
			
			if(menu.children && Object.keys(menu.children).length>0){
				rv.push(<li className="menu-item-has-children">
					<a href="javascript:;">{menu.title}</a>
					<ul className="sub-menu">
						{this.getFirstLevelChild(menu.children)}
					</ul>					
				</li>);
			}
			else{
				if(this.isNewLink(menu.menulink)){
					rv.push(<li>
						<a activeClassName="active" to={"/"+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} >{menu.title}</a>
					</li>);
				}
				else{
					rv.push(<li>
						<NavLink activeClassName="active" to={"/"+menu.menulink} target={menu.open_in_newtab=='1' ? '_blank' : ''} >{menu.title}</NavLink>
					</li>);
				}
			}
		});
		
		return rv;
	}
	
	isNewLink(val){
		try{
			if(val.substr(0,4)=='http')
				return true;
			
			return false;
		}
		catch(error){
			console.log(error);
			return false;
		}
	}
	
	render(){
		
		
		return(
			<ul className="nav-menu" style={{marginRight: '0px'}} >
				
				{this.getMenus(this.props.data)}				
				
			</ul>
		);
	}
	
}
export default Menu;
