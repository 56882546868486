import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {LineLoader} from '../../elements';

import StaticData from './StaticData';

class AboutUs extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadHistoryData();
	}
	
	loadHistoryData(){
		try{
			this.setState({showLoader: true});
			API.get('v1/site/content/home_about_image').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){}
	}
	
	render(){

		return(
			<div id="rs-about" className="rs-about sec-spacer">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12">
							<div className="about-img">
								<img src={Api.ImageUrl + this.state.dataList.data_value} alt={getls('site','sitemeta')} />
							</div>
						</div>
						<div className="col-lg-6 col-md-12">
							<div className="sec-title mb-50">
								<h2><span className="orange-color">ABOUT</span> US</h2>      
								<p>About our school history, mission and vision.</p>
							</div>
							<div id="accordion" className="rs-accordion-style1">
								<div className="card">
									<div className="card-header" id="headingOne">
										<h3 className="acdn-title" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
											Our History
										</h3>
									</div>
									<div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
										<div className="card-body">
											<StaticData dataKey="homepage_history" />
										</div>
									</div>
								</div>
								<div className="card">
									<div className="card-header" id="headingTwo">
										<h3 className="acdn-title collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
											Our Mission
										</h3>
									</div>
									<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
										<div className="card-body">
											<StaticData dataKey="mission" />
										</div>
									</div>
								</div>
								<div className="card">
									<div className="card-header mb-0" id="headingThree">
										<h3 className="acdn-title collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
											Our Vision
										</h3>
									</div>
									<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
										<div className="card-body">
											<StaticData dataKey="vision" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
}
export default AboutUs;
