import {Var} from '../utils/Variables';

import Dashboard from './pages/Dashboard';
import MyProfile from './pages/MyProfile';
import Examaid from './pages/Examaid';
import MobilePages from './pages/Examaid/MobilePages';
import ExamaidAppversion from './pages/ExamaidAppversion';
import AppPages from './pages/AppPages';
import Filemanager from './pages/Filemanager';

import OnlineApplictions from './pages/OnlineAdmission/Applications';

import CMS_MenuList from './pages/CMS/MenuList';
import CMS_MenuOrder from './pages/CMS/MenuList/MenuOrder';
import CMS_Pages from './pages/CMS/Pages';
import CMS_AddPage from './pages/CMS/Pages/AddPage';
import CMS_EditPage from './pages/CMS/Pages/EditPage';
import CMS_Sliders from './pages/CMS/Sliders';
import CMS_Settings from './pages/CMS/Settings';
import CMS_Static_HomePage from './pages/CMS/StaticPages/HomePage';
import CMS_Static_HomePageTeachers from './pages/CMS/StaticPages/HomePageTeachers';
import CMS_Static_HomePageWelcomeBanner from './pages/CMS/StaticPages/HomePageWelcomeBanner';

import CMS_Static_SidebarContainer from './pages/CMS/StaticSidebar/SidebarContainer';

import CMS_Announcement from './pages/CMS/Announcement';
import CMS_Gallery from './pages/CMS/Gallery';
import CMS_Events from './pages/CMS/Events';
import CMS_AddEvent from './pages/CMS/Events/AddEvent';
import CMS_EditEvent from './pages/CMS/Events/EditEvent';

import CKEditor from 'ckeditor4-react';
CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.13.1/full/ckeditor.js';


const pageList = [
	{ name: 'Dashboard', path: '/'+Var.AdminPath+'/app', component: Dashboard },
	{ name: 'My Profile', path: '/'+Var.AdminPath+'/app/myprofile', component: MyProfile },
	{ name: 'Exam Aid Users', path: '/'+Var.AdminPath+'/app/exam-aid', component: Examaid },
	{ name: 'Exam Aid App Pages', path: '/'+Var.AdminPath+'/app/exam-aid/pages/:id', component: MobilePages },
	{ name: 'Exam Aid App Version', path: '/'+Var.AdminPath+'/app/examaid-appversion', component: ExamaidAppversion },
	{ name: 'App Pages', path: '/'+Var.AdminPath+'/app/mob-pages', component: AppPages },
	{ name: 'Filemanager', path: '/'+Var.AdminPath+'/app/filemanager', component: Filemanager },
	
	{ name: 'Applications', path: '/'+Var.AdminPath+'/app/online-admission/applications', component: OnlineApplictions },
	
	{ name: 'Menus', path: '/'+Var.AdminPath+'/app/cms/menus', component: CMS_MenuList },
	{ name: 'Menu Order', path: '/'+Var.AdminPath+'/app/cms/menus/order', component: CMS_MenuOrder },
	{ name: 'Pages', path: '/'+Var.AdminPath+'/app/cms/pages', component: CMS_Pages },
	{ name: 'New Page', path: '/'+Var.AdminPath+'/app/cms/pages/add', component: CMS_AddPage },
	{ name: 'Update Page', path: '/'+Var.AdminPath+'/app/cms/pages/edit/:id/:slug', component: CMS_EditPage },
	{ name: 'Sliders', path: '/'+Var.AdminPath+'/app/cms/sliders', component: CMS_Sliders },
	{ name: 'Settings', path: '/'+Var.AdminPath+'/app/cms/settings', component: CMS_Settings },
	{ name: 'Home Page', path: '/'+Var.AdminPath+'/app/cms/static/homepage', component: CMS_Static_HomePage },
	{ name: 'Our Teachers', path: '/'+Var.AdminPath+'/app/cms/static/homepage/teachers', component: CMS_Static_HomePageTeachers },
	{ name: 'Welcome Banner', path: '/'+Var.AdminPath+'/app/cms/static/homepage/welcomebanner', component: CMS_Static_HomePageWelcomeBanner },
	{ name: 'Sidebar', path: '/'+Var.AdminPath+'/app/cms/static/sidebar', component: CMS_Static_SidebarContainer },
	{ name: 'Announcements', path: '/'+Var.AdminPath+'/app/cms/announcement', component: CMS_Announcement },
	{ name: 'Gallery', path: '/'+Var.AdminPath+'/app/cms/gallery', component: CMS_Gallery },
	{ name: 'Events', path: '/'+Var.AdminPath+'/app/cms/events', component: CMS_Events },
	{ name: 'Add Event', path: '/'+Var.AdminPath+'/app/cms/events/add', component: CMS_AddEvent },
	{ name: 'Edit Event', path: '/'+Var.AdminPath+'/app/cms/events/edit/:id/:slug?', component: CMS_EditEvent },
];

export default pageList;