import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../utils';
import {Api, AppList} from '../../../utils/Variables';
import API from '../../../utils/API';
import {PageLoader} from '../../elements';

import EditVersion from './EditVersion';
import AddPage from './AddPage';

class AppPages extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showEditModal: false,
			editData: [],
			dataList: [],
			dataView: [],
		}
		
		this.handleSearch = this.handleSearch.bind(this);
		this.loadPages = this.loadPages.bind(this);
		this.editComplete = this.editComplete.bind(this);
	}
	
	componentDidMount(){
		
		this.loadPages();
	}
	
	loadPages(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/webadmin/examaid/versions').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearch(e){
		try{
			
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var data = d.filter(function(obj){
				return( (upperCase(obj.version_code).indexOf(v)>-1) ||
				(upperCase(obj.version_name).indexOf(v)>-1));
			});
			
			this.setState({dataView: data});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleEditClick(data){
		
		this.setState({editData: data, showEditModal:true});
	}
	
	editComplete(){
		
		this.setState({showEditModal:false});
		this.loadVehicles();
	}
	
	render(){

		let sno = 1;
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
			
				<Row>
					<Col md={6} >
						<div className="input-group" >
							<input type="text" className="form-control form-control-sm" placeholder="Search by Code, Name" onChange={this.handleSearch} />
							<div className="input-group-append">
								<span className="input-group-text"><i className="ion-android-search" /></span>
							</div>
						</div>
					</Col>
					<Col md={3} >
						<div className="input-group input-group-sm" >
							<div className="input-group-append">
								<span className="input-group-text">App Name</span>
							</div>
							<select className="form-control form-control-sm" >
							<option value="0"> -Select- </option>
							{AppList.map((item,i)=>{
								return <option key={i} value={item.key} > {item.name} </option>;
							})
							}
							</select>
						</div>
					</Col>
					<Col md={3} >
						<div className=" text-right" >
							<ButtonGroup >
							<Button variant="outline-secondary" size="sm" onClick={()=>{this.setState({showAddModal: true})}} >
									<i className="ion-android-add" /> Add Page
							</Button>
								<Button variant="outline-secondary" size="sm" onClick={this.loadPages} >
									<i className="ion-ios7-reload" /> Refresh
								</Button>
							</ButtonGroup >
							
						</div>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						<div className="table-responsive" >
							<Table striped bordered size="sm cl__table__xs" >
								<thead>
									<tr className="bg-light" >
										<th>S.No</th>
										<th>Updated On</th>
										<th>Version Code</th>
										<th>Version Name</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
								{this.state.dataView.map((item,i)=>{
									return <tr key={i} >
										<td>{sno++}</td>
										<td>{item.idate}</td>
										<td>{item.version_code}</td>
										<td>{item.version_name}</td>
										<td align="center" >
											<Button size="sm" onClick={this.handleEditClick.bind(this, item)} >
												<i className="ion-edit"></i>
											</Button>
										</td>
									</tr>;
								})
								}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
				
				
				<Modal show={this.state.showAddModal} onHide={()=>{this.setState({showAddModal:false})}} backdrop="static" size="lg" >
					<Modal.Header closeButton>
						Add Page
					</Modal.Header>
					<Modal.Body>
						<AddPage  />
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditModal} onHide={()=>{this.setState({showEditModal:false})}} backdrop="static" size="lg" >
					<Modal.Header closeButton>
						Edit Version
					</Modal.Header>
					<Modal.Body>
						<EditVersion  data={this.state.editData} afterFinish={this.loadPages} />
					</Modal.Body>
				</Modal>
			
			</React.Fragment>
		);
	}
	
}
export default AppPages;
