import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {PageLoader} from '../../elements';
import {getls} from '../../../utils';
import {Api, classList} from '../../../utils/Variables';
import API from '../../../utils/API';

import IMG_LENGTH from '../../../assets/img/noimage-length.png';
import IMG_SQUARE from '../../../assets/img/noimage-square.png';
import IMG_WIDE from '../../../assets/img/noimage-wide.png';

import ModalFileBrowser from '../Filemanager/ModalFileBrowser';

class ViewApplication extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showfileBrowser: false,
			btnText: 'Update Application',
			topWrapper: [],
			iconWrapper: [],
			sideWrapper: [],
			wrapperType: '',
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFomrSubmit = this.handleFomrSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.setDefaultWrappers();
	}
	
	setDefaultWrappers(){
		try{
			
			this.setState({
				iconWrapper: {
					selectedUrl: this.getFieldValue('logo')
				}
			});
		}
		catch(error){ }
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/")
			
			console.log(files);
			
			var d ={
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			};
			
			
			if(this.state.wrapperType=='top'){
				this.setState({topWrapper: d});
			}
			else if(this.state.wrapperType=='icon'){
				this.setState({iconWrapper: d});
			}
			else {
				this.setState({sideWrapper: d});
			}
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	

	handleFomrSubmit(e){
		e.preventDefault();
		try{
			this.setState({btnText: 'Updating..', showLoader: true});
			
			API.post('v1/webadmin/onlineadmission/application/update', $("#frmUpdateAppform").serialize()).then(res=>{
				if(res['data'].status=='1'){
					this.setState({btnText: 'Update Application', showLoader: false});
					notify.show(res['data'].message, 'success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({btnText: 'Update Application', showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({btnText: 'Update Application', showLoader: false});
		}
	}
	
	handleFiles(e){
		//console.log(e.target.files);
		let file = e.target.files;
		this.setState({selectedFile: file});
	}
	
	getImage(imgType, withUrl=false){
		try{
			/* var d = [];
			if(imgType=='top'){
				d = this.state.topWrapper;
			}
			else if(imgType=='icon'){
				d = this.state.iconWrapper;
			}
			else {
				d = this.state.sideWrapper;
			}
			
			if(Object.keys(d).length>0 && d.selectedUrl!='' && d.selectedUrl!=null){
				
				return (withUrl) ? Api.ImageUrl+d.selectedUrl : d.selectedUrl;
			}
			return false; */
			return  Api.ImageUrl +'/'+this.getFieldValue(imgType);
		}
		catch(error){
			return false;
		}
	}
	
	
	getFieldValue(fieldName){
		try{
			
			var d = this.props.data;
			return d[fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	
	handleApplictionDownload(no){
		try{
			this.setState({showLoader: true});
			var filePath = Api.Url +'v1/pdfapp/'+no;
			var a = document.createElement('A');
			a.href = filePath;
			a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a); 
			
			 /* var form = document.createElement("form");
			var element1 = document.createElement("input"); 
			var element2 = document.createElement("input");  

			form.method = "post";
			form.action = filePath;   
			form.target="_blank";

			element1.value=no;
			element1.name="i__id";
			form.appendChild(element1);  

			document.body.appendChild(form);
			
			form.submit(); */
			
			this.setState({showLoader: false});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		return(
		<>
		
			<PageLoader loading={this.state.showLoader} />
			
			<form action="" method="post" id="frmUpdateAppform" encType="multipart/form-data" onSubmit={this.handleFomrSubmit} >
			
				<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
				<input type="hidden" name="i__applicationId" value={this.getFieldValue('application_id')} />
			
				<Row>
					
					<Col md={10} >
						<Row>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Application No <span className="text-danger"></span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="appno" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('application_no')} readOnly />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>App.Date / Class <span className="text-danger"></span></label>
									</Col>
									<Col md={5} >
										<input type="text" name="i__appon" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('registered_on')}  readOnly />
									</Col>
									<Col md={4} >
										<select name="i__classFor" className="form-control form-control-sm"  >
											<option value=""> -Select- </option>
											{classList.map((item,i)=>{
												return <option key={i} value={item.key} selected={this.getFieldValue('apcation_for')==item.key ? 'selected' : ''} > {item.name} </option>;
											})
											}
										</select>
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Student Name <span className="text-danger"></span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__name" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('student_name')}  style={{textTransform: 'uppercase'}} required  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>DOB / Age <span className="text-danger">*</span></label>
									</Col>
									<Col md={5} >
										<input type="date" name="i__dob" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('date_of_birth')}  />
									</Col>
									<Col md={4} >
										<input type="number" name="i__age" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('age')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Gender <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<select name="i__gender" className="form-control form-control-sm"  >
											<option value=""> -Select- </option>
											<option value="Male" selected={this.getFieldValue('gender')=='Male' ? 'selected' : ''} > Male </option>
											<option value="Female" selected={this.getFieldValue('gender')=='Female' ? 'selected' : ''}  > Female </option>
										</select>
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Religion <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										
										<select name="i__religion" className="form-control form-control-sm"  >
											<option value=""> -Select- </option>
											<option value="Hindu" selected={this.getFieldValue('religion')=='Hindu' ? 'selected' : ''} > Hindu </option>
											<option value="Christian" selected={this.getFieldValue('religion')=='Christian' ? 'selected' : ''}  > Christian </option>
											<option value="Muslim" selected={this.getFieldValue('religion')=='Muslim' ? 'selected' : ''}   > Muslim </option>
											<option value="Others"selected={this.getFieldValue('religion')=='Others' ? 'selected' : ''}   > Others </option>
										</select>
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Caste <span className="text-danger">*</span></label>
									</Col>
									<Col md={3} >
										
										<select name="i__community" className="form-control form-control-sm"  >
											<option value=""> -Select- </option>
											<option value="OC" selected={this.getFieldValue('community')=='OC' ? 'selected' : ''} > OC </option>
											<option value="BC" selected={this.getFieldValue('community')=='BC' ? 'selected' : ''}  > BC </option>
											<option value="BCM" selected={this.getFieldValue('community')=='BCM' ? 'selected' : ''}  > BC-Muslim </option>
											<option value="MBC" selected={this.getFieldValue('community')=='MBC' ? 'selected' : ''}  > MBC </option>
											<option value="DNC" selected={this.getFieldValue('community')=='DNC' ? 'selected' : ''}  > DNC </option>
											<option value="SC" selected={this.getFieldValue('community')=='SC' ? 'selected' : ''}  > SC </option>
											<option value="ST" selected={this.getFieldValue('community')=='ST' ? 'selected' : ''}  > ST </option>
											<option value="Converted-to-christian" selected={this.getFieldValue('community')=='Converted-to-christian' ? 'selected' : ''}  > Convert to Christianity from Scheduled Caste </option>
										</select>
									</Col>
									<Col md={6} >
										<input type="text" name="i__caste" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('caste')}  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Mother Tongue <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__motherTongue" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('mother_tongue')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Nationality <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__nationality" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('nationality')}  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>State <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__state" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('state')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Father Name <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__fatherName" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('father_name')}  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Occu./Edu. <span className="text-danger">*</span></label>
									</Col>
									<Col md={5} >
										<input type="text" name="i__fatherOccupation" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('father_occupation')}  />
									</Col>
									<Col md={4} >
										<input type="text" name="i__fatherEducation" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('father_education')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Mother Name <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__motherName" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('mother_name')}  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Occu./Edu. <span className="text-danger">*</span></label>
									</Col>
									<Col md={5} >
										<input type="text" name="i__motherOccupation" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('mother_occupation')}  />
									</Col>
									<Col md={4} >
										<input type="text" name="i__motherEducation" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('mother_education')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Place Name <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__place" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('place')}  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Mobile/Phone<span className="text-danger">*</span></label>
									</Col>
									<Col md={5} >
										<input type="number" name="i__mobile" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('mobile')}  />
									</Col>
									<Col md={4} >
										<input type="text" name="i__phone" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('phone')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Present Address <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<textarea rows="5"  name="i__presentAddress" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('present_address')}  ></textarea>
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Permanent Address <span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<textarea rows="5"  name="i__permanentAddress" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('permanent_address')}  ></textarea>
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Previous School/Class<span className="text-danger">*</span></label>
									</Col>
									<Col md={5} >
										<input type="text" name="i__school" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('previous_school')}  />
									</Col>
									<Col md={4} >
										<input type="text" name="i__classStudied" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('previous_class')}  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>EMIS/Adhaar<span className="text-danger">*</span></label>
									</Col>
									<Col md={5} >
										<input type="text" name="i__emisNo" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('emis_no')}  />
									</Col>
									<Col md={4} >
										<input type="text" name="i__aadharNo" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('aadhar_no')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Identification<span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__identification1" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('personal_identification_1')}  />
									</Col>
								</Row>
							</Col>
							<Col md={6} >
								<Row>
									<Col md={3} >
										<label>Identification<span className="text-danger">*</span></label>
									</Col>
									<Col md={9} >
										<input type="text" name="i__identification2" className="form-control form-control-sm"  placeholder="" defaultValue={this.getFieldValue('personal_identification_2')}  />
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row>
							<Col md={12} >
								<div className="text-center mt-15" >
									<Button type="button" variant="info" size="sm" onClick={this.handleApplictionDownload.bind(this, this.getFieldValue('application_id'))} >
										Download Application
									</Button>
									&emsp;
									<Button type="submit" variant="success" size="sm"  disabled={this.state.btnText!='Update Application'} >
										{this.state.showLoader ? <i className="ion-loading-c" /> : ''} {this.state.btnText}
									</Button>
								</div>
							</Col>
						</Row>
						
					</Col>
									
					
					<Col md={2} >
						
						<Row >
					
							<Col md={12} >
								<label>Child Photo</label>
								<img src={this.getImage('child_photo', true) || IMG_SQUARE} style={{border: '1px solid grey', width: '100%', cursor: 'pointer'}}  />
							</Col>
							
							<Col md={12} >
								<label>Father Photo</label>
								<img src={this.getImage('father_photo', true) || IMG_SQUARE} style={{border: '1px solid grey', width: '100%', cursor: 'pointer'}}  />
							</Col>
							
							<Col md={12} >
								<label>Mother Photo</label>
								<img src={this.getImage('mother_photo', true) || IMG_SQUARE} style={{border: '1px solid grey', width: '100%', cursor: 'pointer'}}  />
							</Col>
							
							
						</Row>
						
					</Col>
					
				</Row>
								
				
				{/*<Row className="mt-15" >
					<Col md={12} >
						<hr />
						<div className="text-center" >
							<Button type="submit" variant="success" size="sm" disabled={this.state.btnText!='Update User'} >
								{this.state.showLoader ? <i className="ion-loading-c" /> : <i className="ion-checkmark" />}
								&nbsp; {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>*/}
			</form>
			
			
			<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</>
		);
	}
	
}
export default ViewApplication;
