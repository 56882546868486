import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button} from 'react-bootstrap';
import {ToastsStore} from 'react-toasts';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {getls, setlsItem, setls, getlsItem} from '../../../utils';
import API from '../../../utils/API';


class Step1 extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Go Next',
			showLoader: false,
			toLogin: false,
			toOtp: false,
		}
		
		this.handleLoginFormSubmit = this.handleLoginFormSubmit.bind(this);
	}
	
	handleLoginFormSubmit(e){
		e.preventDefault();
		try{

			this.setState({showLoder: true, btnText: 'Processing..'});
			API.post('/v1/web/registration', $("#frmRegistration_1").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmRegistration_1").reset();
					//notify.show(res['data'].message, 'success');
					setlsItem('reg_hadotp', 'yes');
					setls('user', res['data'].data);
					ToastsStore.success(res['data'].message);
					this.setState({showLoader: false, btnText: 'Go Next', toOtp: true});
				}
				else{
					//notify.show(res['data'].message, 'error');
					ToastsStore.error(res['data'].message);
					this.setState({showLoader: false, btnText: 'Go Next'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'Go Next'});
		}
	}
	
	render(){

		/* if(this.state.toLogin){
			return(<Redirect to="/" />);
		} */
		if(this.state.toOtp){
			return(<Redirect to="/admission/mobile-verification" />);
		}
		
		return(
			<React.Fragment>
			
				<Container className="pt-10"  >
					<Row>
						<Col md={{ span:6, offset: 3}} >
							<Card>
								<Card.Body>
									<Row>
									
										<Col md={12} >
											<form action="" method="post" id="frmRegistration_1" encType="multipart/form-data" onSubmit={this.handleLoginFormSubmit} >
											
											<h4 className="text-center" >New Application Registration</h4>
											<hr />
											
											<Row className="mt-15" >
												<Col md={12} >
													<label>Name</label>
													<input type="text" className="form-control form-control-sm" required name="i__name" placeholder="Your Name" />
												</Col>												
											</Row>
											
											<Row className="mt-15" >
												<Col md={12} >
													<label>Mobile No</label>
													<input type="number" className="form-control form-control-sm" required name="i__mobileNo" placeholder="Your Primary Mobile No" />
												</Col>
												
											</Row>
											
											<Row className="mt-15" >
												<Col md={12} >
													<label>Password</label>
													<input type="password" className="form-control form-control-sm" required name="i__password" placeholder="Your Login Password" />
												</Col>
												
											</Row>
											
											<Row className="mt-15" >
												<Col md={12} >
													<label>Confirm Password</label>
													<input type="password" className="form-control form-control-sm" required name="i__confirmPassword" placeholder="Retype Your Login Password" />
												</Col>
												
											</Row>
											
											
											<Row className="mt-15" >
												
												<Col md={12} >
													<div className="text-right" >
														<Button type="submit" size="md" variant="success" block disabled={this.state.btnText!='Go Next'} >
															{this.state.btnText} <i className="ion-arrow-right-c" />
														</Button>
													</div>
												</Col>
												<Col md={12} > <hr /> </Col>
												<Col md={12} >
													<Link to="/admission"  className="btn btn-info btn-md btn-block" >
														<i className="ion-arrow-left-c" /> Back to Login
													</Link>
												</Col>
											</Row>
											
											
											
											</form>
											
										</Col>
										
										{/*<Col md={6} >
											<h4>Online registration</h4>
											<p>Please click the below link to register your child profile</p>
											
										</Col>*/}
										
										
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			
			</React.Fragment>
		);
	}
	
}
export default Step1;
