import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import BarLoader from '../BarLoader';

import Menu from './Menu';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showLoader: false,
			dataList: [],
		}		
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
	}
	
	componentDidMount(){
		 //window.addEventListener('scroll', this.handleWindowScroll);
		this.loadMenus();
		
		this.handleWindowScroll();
	}
	
	handleWindowScroll(){
		
		var header = $('.menu-sticky');
			var win = $(window);
			win.on('scroll', function() {
			   var scroll = win.scrollTop();
			   if (scroll < 300) {
				   header.removeClass("sticky");
			   } else {
				   header.addClass("sticky");
			   }
			});
			
			$(window).on( 'load', function() {
				//rs menu
				if($(window).width() < 992) {
				  $('.rs-menu').css('height', '0');
				  $('.rs-menu').css('opacity', '0');
				  $('.rs-menu-toggle').on( 'click', function(){
					 $('.rs-menu').css('opacity', '1');
				 });
				}
			});
	}


	loadMenus(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/site/menus').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		
		$('html, body').animate({scrollTop:0}, 'slow');
				
		return(
		<>
			<Helmet>
				<meta name="author" content="Nidhir Dev Groups" />
				<meta name="keyword" content={getls('site','sitemeta')} />
				<meta name="description" content={getls('site','sitemeta')} />
				<title>{getls('site','sitetitle')}</title>
				<link rel="icon" type="image/png" href={Api.ImageUrl +'/'+ getls('site','favicon')} />
			</Helmet>
			
			<header id="rs-header" className="rs-header">
				
				<div className="menu-area menu-sticky">
					<div className="container">
				        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="logo-area">
                                    <Link to="/" >
										<img src={Api.ImageUrl + '/'+ getls('site','logo')} alt={getls('site','sitemeta')} />
									</Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="main-menu">
                                    <a className="rs-menu-toggle"><i className="fa fa-bars"></i>Menu</a>
                                    <nav className="rs-menu">
									
									{this.state.showLoader ? <BarLoader/> : <Menu data={this.state.dataList} />}
										
                                    </nav>
									{/*<div className="apply-box">
									<a href="#" className="apply-btn">Admission</a>
									</div>*/}
                            </div>
                        </div>
						</div>
					</div>
				</div>
			</header>
			</>
		);
	}
	
}
export default Header;
