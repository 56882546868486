import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';

import AddSlider from './AddSlider';
import EditSlider from './EditSlider';

class Sliders extends Component{
	constructor(props) {
		super(props);
		this.state = {
		  showAddSlider: false,
		  showEditSlider: false,
		  editData: [],
		  dataList: [],
		  dataView: [],
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadSliders = this.loadSliders.bind(this);
	}
	
	componentDidMount(){
		
		this.loadSliders();
	}
	
	loadSliders(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/webadmin/sliders/').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});	
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.title).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Slider?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/webadmin/slider/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item,e){
		try{
			
			this.setState({
				editData: item,
				showEditSlider: true
			});
		}
		catch(error){
			
		}
	}
	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				
				<Row>
					<Col md={9} >
						<div className="input-group" >
							<input type="text" className="form-control form-control-sm" placeholder="Search by Title..." onChange={this.handlePageSearch} />
							<div className="input-group-append">
								<span className="input-group-text"><i className="ion-android-search" /></span>
							</div>
						</div>
					</Col>
					<Col md={3} >
						<div className="text-right" >
							<ButtonGroup >
								<Button type="button" size="sm"  variant="outline-secondary" onClick={()=>{this.setState({showAddSlider: true})}} >
									<span className="ion-android-add"></span> Add Slider
								</Button>
								
								<Button variant="outline-secondary" size="sm" onClick={this.loadSliders} >
									<i className="ion-ios7-reload" /> Refresh
								</Button>
							</ButtonGroup >
						</div>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						
							{this.state.showLoader ? <center><Loader type="dot" /></center> : 
								<Table hover bordered size="sm cl__table__xs" >
									<thead>
										<tr>
											<th width="80" >S.No</th>
											<th>Slider</th>
											<th width="100" >Order</th>
											<th width="120" >Action</th>
										</tr>
									</thead>
									<tbody>
									{this.state.dataView.map((item,i)=>{
										return <tr key={i} className={item.activestatus!='1' ? 'bg-danger-light' : ''} >
											<td>{sno++}</td>
											<td>
												<img src={Api.ImageUrl+item.url} height={100} />
												<br />
												Title : {item.title}
											</td>
											<td align="center">{item.slideorder}</td>
											<td align="center" >
												
												<Button type="button" size="sm" color="primary" title="Edit" onClick={this.handleEdit.bind(this,item)} >
													<span className="ion-edit"></span>
												</Button>
												&nbsp;
												<Button type="button" size="sm" variant="danger" title="Remove" onClick={this.handleRemove.bind(this,item,i)} >
													<span className="ion-close-round"></span>
												</Button>
											</td>
										</tr>;
									})
									}
									</tbody>
								</Table>
							}
					</Col>
				</Row>
				
				<Modal show={this.state.showAddSlider} onHide={()=>{this.setState({showAddSlider:false})}} backdrop="static" size="lg" className="" >
					<Modal.Header closeButton>
						Add Slider
					</Modal.Header>
					<Modal.Body>
						<AddSlider atClose={()=>{this.setState({showAddSlider: false})}} atFinish={this.loadSliders} />
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditSlider} onHide={()=>{this.setState({showEditSlider:false})}} backdrop="static" size="lg" className="" >
					<Modal.Header closeButton>
						Edit Slider
					</Modal.Header>
					<Modal.Body>
						<EditSlider atClose={()=>{this.setState({showEditSlider: false})}} editData={this.state.editData} atFinish={this.loadSliders}  />
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default Sliders;
