import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import {Api} from '../../../utils/Variables';
import API from '../../../utils/API';

import IMG_LENGTH from '../../../assets/img/noimage-length.png';
import IMG_SQUARE from '../../../assets/img/noimage-square.png';
import IMG_WIDE from '../../../assets/img/noimage-wide.png';

import ModalFileBrowser from '../Filemanager/ModalFileBrowser';

class AddUser extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showfileBrowser: false,
			btnText: 'Save User',
			topWrapper: [],
			iconWrapper: [],
			sideWrapper: [],
			wrapperType: '',
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFomrSubmit = this.handleFomrSubmit.bind(this);
	}
	
	componentDidMount(){
		
		//this.setDefaultWrappers();
	}
	
	setDefaultWrappers(){
		try{
			
			this.setState({
				iconWrapper: {
					selectedUrl: this.getFieldValue('home_page_wrapper')
				},
				topWrapper: {
					selectedUrl: this.getFieldValue('header')
				},
				sideWrapper: {
					selectedUrl: this.getFieldValue('sidewrapper')
				}
			});
		}
		catch(error){ }
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/")
			
			console.log(files);
			
			var d ={
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			};
			
			
			if(this.state.wrapperType=='top'){
				this.setState({topWrapper: d});
			}
			else if(this.state.wrapperType=='icon'){
				this.setState({iconWrapper: d});
			}
			else {
				this.setState({sideWrapper: d});
			}
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	

	handleFomrSubmit(e){
		e.preventDefault();
		try{
			this.setState({btnText: 'Updating..'});
			
			API.post('v1/webadmin/examaid/user/new', $("#frmAddExamUser").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddExamUser").reset();
					this.setState({iconWrapper: [], btnText: 'Save User'});
					notify.show(res['data'].message, 'success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({btnText: 'Save User'});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({btnText: 'Save User'});
		}
	}
	
	handleFiles(e){
		//console.log(e.target.files);
		let file = e.target.files;
		this.setState({selectedFile: file});
	}
	
	getImage(imgType, withUrl=false){
		try{
			var d = [];
			if(imgType=='top'){
				d = this.state.topWrapper;
			}
			else if(imgType=='icon'){
				d = this.state.iconWrapper;
			}
			else {
				d = this.state.sideWrapper;
			}
			
			if(Object.keys(d).length>0 && d.selectedUrl!='' && d.selectedUrl!=null){
				
				return (withUrl) ? Api.ImageUrl+d.selectedUrl : d.selectedUrl;
			}
			return false;
		}
		catch(error){
			return false;
		}
	}
	
	
	getFieldValue(fieldName){
		try{
			
			var d = this.props.data;
			return d[fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	render(){

		return(
		<>
		
			<form action="" method="post" id="frmAddExamUser" encType="multipart/form-data" onSubmit={this.handleFomrSubmit} >
			
				<Row>
					
					<Col md={9} >
						
						<Row>
							<Col md={3} >
								<label>School Name <span className="text-danger">*</span></label>
							</Col>
							<Col md={9} >
								<input type="text" name="i__name" className="form-control form-control-sm" required placeholder="Name of the School" />
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={3} >
								<label>School Place <span className="text-danger">*</span></label>
							</Col>
							<Col md={9} >
								<input type="text" name="i__place" className="form-control form-control-sm" required placeholder="Place of the School" />
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={3} >
								<label>Service URL<span className="text-danger">*</span></label>
							</Col>
							<Col md={9} >
								<input type="text" name="i__serviceUrl" className="form-control form-control-sm" required placeholder="http://domain.in/api"/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={3} >
								<label>School Address <span className="text-danger">*</span></label>
							</Col>
							<Col md={9} >
								<textarea rows="5" name="i__address" className="form-control form-control-sm" required placeholder="School Address.." ></textarea>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={3} >
								<label>Contact Phone <span className="text-danger">*</span></label>
							</Col>
							<Col md={9} >
								<input type="number" name="i__mobile" className="form-control form-control-sm" required placeholder="Phone Number" />
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={3} >
								<label>Email Id <span className="text-danger"></span></label>
							</Col>
							<Col md={9} >
								<input type="email" name="i__email" className="form-control form-control-sm" placeholder="Email Id" />
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={3} >
								<label>School WebsiteRL<span className="text-danger"></span></label>
							</Col>
							<Col md={9} >
								<input type="text" name="i__website" className="form-control form-control-sm" placeholder="http://schoolname.in"/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={3} >
								<label>Status<span className="text-danger">*</span></label>
							</Col>
							<Col md={9} >
								<select name="i__status" className="form-control form-control-sm" required >
									<option value="1" > Active </option>
									<option value="0" > In-Active </option>
								</select>
							</Col>
						</Row>
						
					</Col>
					
					<Col md={3} >
						
						<Row >
					
							<Col md={12} >
								<label>School Logo</label>
								<img src={this.getImage('icon', true) || IMG_SQUARE} style={{border: '1px solid grey', width: '100%', cursor: 'pointer'}} onClick={()=>{this.setState({wrapperType: 'icon', showfileBrowser: true})}} />
							</Col>
							
						</Row>
						
					</Col>
					
				</Row>
								
				<input type="hidden" name="i__logo" value={this.state.iconWrapper.selectedUrl} />
				
				<Row className="mt-15" >
					<Col md={12} >
						<hr />
						<div className="text-center" >
							<Button type="submit" variant="success" size="sm" disabled={this.state.btnText!='Save User'} >
								{this.state.showLoader ? <i className="ion-loading-c" /> : <i className="ion-checkmark" />}
								&nbsp; {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>
			</form>
			
			
			<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</>
		);
	}
	
}
export default AddUser;
