import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, setlsItem,getlsItem} from '../../../utils';
import {Api} from '../../../utils/Variables';
import API from '../../../utils/API';
import {LineLoader, SiteSlider} from '../../elements';

import AboutUs from './AboutUs';
import Events from './Events';
import Announcements from './Announcements';
import Teachers from './Teachers';

const styles = {
	closeBtn: {
	    position: 'absolute',
		right: '-20px',
		background: 'red',
		color: '#fff',
		border: '1px solid grey',
		borderRadius: '100px',
		padding: '5px 13px',
		fontWeight: '800',
		top: '-20px',
	}
}

class WelcomeBanner extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showPopupModal: false,
			oneTime: false,
			dataList: [],
		}
		
		this.handleModalclose = this.handleModalclose.bind(this);
	}
	
	componentDidMount(){
		
		this.loadHistoryData();
	}
	
	loadHistoryData(){
		try{
			this.setState({showLoader: true});
			API.get('v1/site/content/home_welcome_banner').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data, 
						showPopupModal: res['data'].data.active_status=='1' && getlsItem('home_welcome_banner_showed')!='yes', 
						oneTime: res['data'].data.data_2=='onetime', 
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){}
	}
	
	getValue(fieldName){
		try{
			var d = this.state.dataList;
			return d[fieldName];
		}
		catch(error){} 
	}
	
	handleModalclose(){
		try{
			
			if(this.state.oneTime){
				
				setlsItem('home_welcome_banner_showed','yes');
			}
			
			this.setState({showPopupModal: false});
			
		}
		catch(error){}
	}
	
	render(){

		var v = this.getValue('data_1');
		return(
			<React.Fragment>
				
				
				<Modal show={this.state.showPopupModal} onHide={this.handleModalclose} size="lg" backdrop="static" >
					<Modal.Body style={{padding: '3px'}} >
						<button type="button" style={styles.closeBtn} onClick={this.handleModalclose} >
							X
						</button>
						{v!=undefined && v!='' && v!=null && v.length>4 ? 
							<a href={v} target="_blank" onClick={this.handleModalclose} >
								<img src={Api.ImageUrl+this.getValue('data_value')} />
							</a>
							:
							<img src={Api.ImageUrl+this.getValue('data_value')} />
						}
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}
	
}
export default WelcomeBanner;
