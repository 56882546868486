import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import Select from 'react-select';

class AddMenu extends Component{
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Save',
			showLoader: false,
			linkList: [],
			selectedPage: [],
			defaultType: 'page',
		}
		this.handleUrlChange = this.handleUrlChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadAllMenus();
	}
	
	
	loadAllMenus(){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/webadmin/post/links/page').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({linkList: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	handleUrlChange(e){
		try{
			
		}
		catch(error){
			
		}
	}
	
	handleSelectChange(sel){
		try{
			
			var id = sel.value;
			
			var d = this.state.linkList.filter(function(obj){
				return (obj.id==id);
			});
			
			this.setState({selectedPage: d[0]});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/webadmin/menus/save', $("#frm_addMenu").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					document.getElementById("frm_addMenu").reset();
					this.setState({showLoader: false, btnSave: 'Save'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Save'});
				}
			});
		}
		catch(error){
			alert('Unexpected Error');
			this.setState({showLoader: false, btnSave: 'Save'});
		}
	}
	
	getSelectOptions(){
		try{
			
			var d = this.state.linkList.map((item,i)=>{
				return {value:item.id, label:item.pagetitle};
			});
			//d.push({value:'0', label: 'Empty'});
			
			return d;
			
		}
		catch(error){
			return [];
		}
	}
	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_addMenu" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__pageId" value={this.state.selectedPage.id} />
					<input type="hidden" name="i__pageUrl" value={this.state.selectedPage.pageurl} />
					
					<Row>
						<Col md={12} >
							<label>Title <span className="text-danger">*</span></label>
							<input type="text" className="form-control form-control-sm" name="i__title" size="sm" required placeholder="Menu Title" />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={12}>
							<label > Menu Type<span className="text-danger"></span></label>
						</Col>
						<Col md={4} >
							<label className="chk-container">From Page
							  <input type="radio" name="i__pagetype" defaultChecked={this.state.defaultType=='page'} onChange={()=>{this.setState({defaultType: 'page'})}}  value="page" />
							  <span className="checkmark"></span>
							</label>
						</Col>
						<Col md={6} >
							<label className="chk-container">External Link
							  <input type="radio" name="i__pagetype" defaultChecked={this.state.defaultType=='external'} onChange={()=>{this.setState({defaultType: 'external'})}} value="external" />
							  <span className="checkmark"></span>
							</label>
						</Col>
					</Row>
					
					{this.state.defaultType=='page' ? 
					<Row className="mt-10" >
						<Col md={12} >
							<label>Page <span className="text-danger">*</span></label>
							<Select 
								onChange={this.handleSelectChange} 
								options={this.getSelectOptions()}
							/>
						</Col>
					</Row>
					:
					<Row className="mt-10" >
						<Col md={12} >
							<label>Page Link <span className="required">*</span></label>
							<input className="form-control form-control-sm" type="text" name="i__externalUrl" />
						</Col>
					</Row>
					}
					
					<Row className="mt-20" >
						<Col md={12} >
							<label className="chk-container">Open in new tab
							  <input type="checkbox" name="i__inNewTab" value="0" />
							  <span className="checkmark"></span>
							</label>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<hr />
							<div className="float-left" >
								<label className="chk-container">Active
								  <input type="checkbox" defaultChecked={true} name="i__active" />
								  <span className="checkmark"></span>
								</label>
							</div>
							<div className="float-right" >
								<Button type="reset" variant="danger" size="sm" onClick={this.props.atClose} >
									Cancel 
								</Button>
								&nbsp;
								<Button type="submit" variant="success" size="sm" disabled={this.state.btnSave!='Save'} >
									{this.state.btnSave=='Save' ? 
									<span className="ion-checkmark"></span> : 
									<span className="ion-loading-a"></span>}
									&nbsp; {this.state.btnSave}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
				
			</React.Fragment>
		);
	}
	
}
export default AddMenu;
