import React from 'react';
import { NavLink } from 'react-router-dom';
import NavBadge from './NavBadge';

const NavDropdownSingleItem = ({ item,role }) => {
  const Icon = null;
  const ionIcon = item.ionicon ? <span className="nav_ionicon"><span className={item.ionicon} ></span></span> : null;
  if (item.external) {
    return (
      <li className="nav-item">
        <a href={item.url}>
			{/*{item.icon && Icon && <Icon className="side-nav-icon" />}*/}
				{ionIcon}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </a>
      </li>
    );
  } else {
	
		if(item.allowed) {
			  if(item.allowed.indexOf(role)>-1){
				return (
				  <li className="nav-item">
					<NavLink to={item.url} activeClassName="active">
						{/*{item.icon && Icon && <Icon className="side-nav-icon" />}*/}
						{ionIcon}
					  <span className="nav-item-label">{item.name}</span>
					  {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
					</NavLink>
				  </li>
				);
			  }
			  else return null;
		  }
		  else{
			  return (
				  <li className="nav-item">
					<NavLink to={item.url} className="nav-link" activeClassName="active">
						{/*{item.icon && Icon && <Icon className="side-nav-icon" />}*/}
						<div className="sb-nav-link-icon">{ionIcon}</div>
					  <span className="nav-item-label">{item.name}</span>
					  {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
					</NavLink>
				  </li>
				);
		  }
	}
};

export default NavDropdownSingleItem;
