import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button, Form} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import ReactFileReader from 'react-file-reader';
import {notify} from 'react-notify-toast';
import {ToastsStore} from 'react-toasts';
import {PageLoader} from '../../elements';
import {getls, numberToMonth} from '../../../utils';
import {classList} from '../../../utils/Variables';
import API from '../../../utils/API';

import Instruction from './Instruction';
import NOIMAGE from '../../../assets/img/noimage-square.png';

class NewApplication extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save Application',
			fatherPhoto: '',
			motherPhoto: '',
			childPhoto: '',
			toList: false,
			showLoader: false,
		}
		
		this.handleFatherPhotochange = this.handleFatherPhotochange.bind(this);
		this.handleMotherPhotochange = this.handleMotherPhotochange.bind(this);
		this.handleChildPhotochange = this.handleChildPhotochange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFatherPhotochange(files){
		
		this.setState({fatherPhoto: files.base64});
	}
	
	handleMotherPhotochange(files){
		
		this.setState({motherPhoto: files.base64});
	}
	
	handleChildPhotochange(files){
		
		this.setState({childPhoto: files.base64});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			/*if(this.state.fatherPhoto==''){
				ToastsStore.warning('Please upload father photo');
				return;
			}
			
			if(this.state.motherPhoto==''){
				ToastsStore.warning('Please upload mother photo');
				return;
			}
			
			if(this.state.childPhoto==''){
				ToastsStore.warning('Please upload child photo');
				return;
			}
			*/	
			
			this.setState({btnText: 'Uploading..', showLoader: true});
			API.post('v1/web/application/save', $("#frmNewAdmission").serialize()).then(res=>{
				if(res['data'].status=='1'){
					ToastsStore.success(res['data'].message);
					document.getElementById("frmNewAdmission").reset();
					this.setState({btnText: 'Save Application', showLoader: false, fatherPhoto: '', motherPhoto: '', childPhoto: '', toList: true});
				}
				else{
					ToastsStore.error(res['data'].message);
					this.setState({btnText: 'Save Application', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Save Application', showLoader: false});
		}
	}
	
	render(){

		if(this.state.toList){
			return(<Redirect to="/admission/filled-applications" />);
		}
		
		var day = [];
		for(var i=1;i<=31;i++){
			day.push(("0" + i).slice(-2));
		}
		
		var month = [];
		for(var i=1;i<=12;i++){
			month.push(("0" + i).slice(-2));
		}
		
		var year = [];
		for(var i= ((new Date()).getFullYear()-2);i>=((new Date()).getFullYear()-16);i--){
			year.push(i);
		}
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
				
				<Container className="pt-20"  >
					<Row>
						<Col md={{span: 8, offset:2 }} >
						
							<Instruction />
							<br />
							
							<Card>
								<Card.Body>
									<Row>
										<Col md={12} >
											
											<h5 className="text-center" >Application</h5>
											<hr />
											
											<form action="" method="post" id="frmNewAdmission" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
											
											<input type="hidden" name="i__id" value={getls('admission_user', 'id')} />
											<input type="hidden" name="i__loginMobile" value={getls('admission_user', 'mobile')} />
											<input type="hidden" name="i__fatherPhoto" value={this.state.fatherPhoto} />
											<input type="hidden" name="i__motherPhoto" value={this.state.motherPhoto} />
											<input type="hidden" name="i__childPhoto" value={this.state.childPhoto} />
											
											<ul className="list-unstyled m-x-n m-b-0">
												<li className="b-t p-a-1 mb-10">
													<Row>
														<Col md={4} >
															<label>Father Photo</label>
															<ReactFileReader fileTypes={[".png",".jpg"]} base64={true}  handleFiles={this.handleFatherPhotochange} >
																<img src={this.state.fatherPhoto || NOIMAGE} style={{cursor: 'pointer'}} className="img-fluid img-thumbnail" />
															</ReactFileReader>
														</Col>
														<Col md={4} >
															<label>Mother Photo</label>
															<ReactFileReader fileTypes={[".png",".jpg"]} base64={true}  handleFiles={this.handleMotherPhotochange} >
																<img src={this.state.motherPhoto || NOIMAGE} style={{cursor: 'pointer'}} className="img-fluid img-thumbnail" />
															</ReactFileReader>
														</Col>
														<Col md={4} >
															<label>Child Photo</label>
															<ReactFileReader fileTypes={[".png",".jpg"]} base64={true}  handleFiles={this.handleChildPhotochange} >
																<img src={this.state.childPhoto || NOIMAGE} style={{cursor: 'pointer'}} className="img-fluid img-thumbnail" />
															</ReactFileReader>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Application for the Class <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<select name="i__classFor" className="form-control form-control-sm" required >
																<option value=""> -Select- </option>
																{classList.map((item,i)=>{
																	return <option key={i} value={item.key} > {item.name} </option>;
																})
																}
															</select>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Student Name <span className="text-danger">*</span></label>
														</Col>
														<Col md={2} >
															<input type="text" className="form-control form-control-sm" required name="i__initial" placeholder="Initial" style={{textTransform: 'uppercase'}} />
														</Col>
														<Col md={6} className="" >
															<input type="text" className="form-control form-control-sm" required name="i__name" placeholder="Name of the Student" style={{textTransform: 'uppercase'}} />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Gender <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<select name="i__gender" className="form-control form-control-sm" required >
																<option value=""> -Select- </option>
																<option value="Male"> Male </option>
																<option value="Female"> Female </option>
															</select>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Date of Birth <span className="text-danger">*</span></label>
														</Col>
														<Col md={2} className="" >
															<select className="form-control form-control-sm" required name="i__dobDay" required >
																<option value=""> Day </option>
																{day.map((item,i)=>{
																	return <option value={item} > {item} </option>;
																})
																}
															</select>
														</Col>
														<Col md={3} className=""  >
															<select className="form-control form-control-sm" required name="i__dobMonth" required >
																<option value=""> Month </option>
																{month.map((item,i)=>{
																	return <option value={item} > {numberToMonth(item)} </option>;
																})
																}
															</select>
														</Col>
														<Col md={3} className=""  >
															<select className="form-control form-control-sm" required name="i__dobYear" required >
																<option value=""> Year </option>
																{year.map((item,i)=>{
																	return <option value={item} > {item} </option>;
																})
																}
															</select>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Age (as on 31st July on this academic year) <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="number" className="form-control form-control-sm" required name="i__age" placeholder="Age" max="20" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Mother Tongue <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" required name="i__motherTongue" placeholder="Mother Tongue" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Religion <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<select name="i__religion" className="form-control form-control-sm" required >
																<option value=""> -Select- </option>
																<option value="Hindu"> Hindu </option>
																<option value="Christian"> Christian </option>
																<option value="Muslim"> Muslim </option>
																<option value="Others"> Others </option>
															</select>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Community <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<select name="i__community" className="form-control form-control-sm" required >
																<option value=""> -Select- </option>
																<option value="OC"> OC </option>
																<option value="BC"> BC </option>
																<option value="BCM"> BC-Muslim </option>
																<option value="MBC"> MBC </option>
																<option value="DNC"> DNC </option>
																<option value="SC"> SC </option>
																<option value="ST"> ST </option>
																<option value="Converted-to-christian"> Convert to Christianity from Scheduled Caste </option>
															</select>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Caste <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" required name="i__caste" placeholder="Type your Caste" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Nationality <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" required name="i__nationality" placeholder="Type your Nationality" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>State <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" required name="i__state" placeholder="Type your State" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Name of the Father / Occupation <span className="text-danger">*</span></label>
														</Col>
														<Col md={5} >
															<input type="text" className="form-control form-control-sm" required name="i__fatherName" placeholder="Name of the Father" />
														</Col>
														<Col md={3} >
															<input type="text" className="form-control form-control-sm" required name="i__fatherOccupation" placeholder="Occupation of the Father" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Education Qualification of Father <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" required name="i__fatherEducation" placeholder="Education Qualification of Father" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Name of the Mother / Occupation <span className="text-danger">*</span></label>
														</Col>
														<Col md={5} >
															<input type="text" className="form-control form-control-sm" required name="i__motherName" placeholder="Name of the Mother" />
														</Col>
														<Col md={3} >
															<input type="text" className="form-control form-control-sm" required name="i__motherOccupation" placeholder="Occupation of the Mother" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Education Qualification of Mother <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" required name="i__motherEducation" placeholder="Education Qualification of Mother" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Place living in <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" required name="i__place" placeholder="Place" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Present Address <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<textarea rows="5" className="form-control form-control-sm" required name="i__presentAddress" placeholder="Type your present address" ></textarea>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Permanent Address <span className="text-danger">*</span></label>
														</Col>
														<Col md={8} >
															<textarea rows="5" className="form-control form-control-sm" required name="i__permanentAddress" placeholder="Type your Permanent address" ></textarea>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Contact Mobile / Phone no <span className="text-danger">*</span></label>
														</Col>
														<Col md={4} >
															<input type="number" className="form-control form-control-sm" required name="i__mobile" placeholder="Mobile Number" />
														</Col>
														<Col md={4} >
															<input type="text" className="form-control form-control-sm" name="i__phone" placeholder="Phone Number" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>School Previously attended and class studied <span className="text-danger"></span></label>
														</Col>
														<Col md={5} >
															<input type="text" className="form-control form-control-sm" name="i__school" placeholder="School Name" />
														</Col>
														<Col md={3} >
															<input type="text" className="form-control form-control-sm" name="i__classStudied" placeholder="Class Studied" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>EMIS Number <span className="text-danger"></span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" name="i__emisNo" placeholder="EMIS Number" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Aadhar Number <span className="text-danger"></span></label>
														</Col>
														<Col md={8} >
															<input type="text" className="form-control form-control-sm" name="i__aadharNo" placeholder="Aadhar Number" />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={4} >
															<label>Personal Identification Marks <span className="text-danger"></span></label>
														</Col>
														<Col md={8} >
															<Row>
																<Col md={12} >
																	<input type="text" className="form-control form-control-sm" name="i__identification1" placeholder="Personal Identification Mark " />
																</Col>
																<Col md={12} className="mt-10" >
																	<input type="text" className="form-control form-control-sm" name="i__identification2" placeholder="Personal Identification Mark " />
																</Col>
															</Row>
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={8} >
															<label>Whether Transfer Cetificate is attached ? <span className="text-danger"></span></label>
														</Col>
														<Col md={4} >
															<Form.Check
																custom
																inline
																label="Yes"
																type="radio"
																id="tc-1"
																name="i__tcAttached"
																value="1"
															  />
															  <Form.Check
																custom
																inline
																label="No"
																type="radio"
																id="tc-2"
																name="i__tcAttached"
																value="0"
															  />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={8} >
															<label>Whether Birth Cetificate is attached ? <span className="text-danger"></span></label>
														</Col>
														<Col md={4} >
															<Form.Check
																custom
																inline
																label="Yes"
																type="radio"
																id="bc-1"
																name="i__birthAttached"
																value="1"
															  />
															  <Form.Check
																custom
																inline
																label="No"
																type="radio"
																id="bc-2"
																name="i__birthAttached"
																value="0"
															  />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={8} >
															<label>Whether Elementary School Cetificate is attached ? <span className="text-danger"></span></label>
														</Col>
														<Col md={4} >
															<Form.Check
																custom
																inline
																label="Yes"
																type="radio"
																id="es-1"
																name="i__eleCerhAttached"
																value="1"
															  />
															  <Form.Check
																custom
																inline
																label="No"
																type="radio"
																id="es-2"
																name="i__eleCerhAttached"
																value="0"
															  />
														</Col>
													</Row>
												</li>
												<li className="b-t p-a-1">
													<Row>
														<Col md={12} >
															<div className="text-center" >
															<hr /> 
																<Button type="submit" variant="success" size="md" disabled={this.state.btnText!='Save Application'} >
																	{this.state.btnText}
																</Button>
															</div>
														</Col>
													</Row>
												</li>
											</ul>
											
											</form>
											
										</Col>
										
										
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			
			</React.Fragment>
		);
	}
	
}
export default NewApplication;
