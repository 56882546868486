import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {ToastsStore} from 'react-toasts';
import {getls, setlsItem, setls, getlsItem} from '../../../utils';
import API from '../../../utils/API';
import LOGO from '../../../assets/img/Sri-Vaiyapuri-Vidyala-logo.png';

class ForgotPassword extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Forgot Password',
			showLoader: false,
			data: [],
		}
		
		this.doForgot_Password = this.doForgot_Password.bind(this);
	}
	
	doForgot_Password(e){
		e.preventDefault();
		try{
			this.setState({btnText: 'Validation...', showLoader: true});
			API.post('v1/web/resetpass/check', $("#frmForgot_Password").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					setlsItem('pass_reset_data', res['data'].data);
					this.setState({data: res['data'].data, isRedirect: true});
				}
				else{
					ToastsStore.error(res['data'].message);
					this.setState({btnText: 'Forgot Password', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Forgot Password', showLoader: false});
		}
	}
	
	render(){

		if(this.state.isRedirect){
			return(<Redirect to={{
				pathname: "/admission/reset-password",
				state: { data: this.state.data}
			}}	/>);
		}
		
		return(
			<React.Fragment>
			
				<Container className="pt-20"  >
					<Row>
						<Col md={{ span:6, offset: 3}} >
							
							<Card>
								<Card.Body>
									<Row>
										<Col md={12} >
											<form action="" method="post" id="frmForgot_Password" encType="multipart/form-data" onSubmit={this.doForgot_Password} >
											
											<h4 className="text-center" >Forgot Password</h4>
											<hr />
											
											<p>Please enter your register mobile number to reset your password</p>
											
											<Row>
												<Col md={12} >
													<label>Mobile No</label>
													<input type="number" className="form-control form-control-sm" required name="i__mobile" placeholder="Your registered Mobile No" />
													<hr />
												</Col>
											</Row>
																						
											<Row className="mt-1" >
												<Col md={6} >
													<Link to="/admission"  className="btn btn-info btn-block btn-md" >
														<i className="ion-arrow-left-c"></i> &nbsp; Back to Login
													</Link>
												</Col>
												<Col md={6} >
													
													<div className="text-right" >
														<Button type="submit" size="md" variant="warning" block disabled={this.state.btnText!='Forgot Password'} >
															{this.state.btnText!='Forgot Password' ?<i className="ion ion-loading-c" /> : null} 
															&nbsp; {this.state.btnText}
														</Button>
													</div>
												</Col>
												<Col md={12} > <hr /> </Col>
											</Row>
											
											</form>
											
										</Col>
										
										{/*<Col md={6} >
											<h4>Online registration</h4>
											<p>Please click the below link to register your child profile</p>
											
										</Col>*/}
										
										
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			
			</React.Fragment>
		);
	}
	
}
export default ForgotPassword;
