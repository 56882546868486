import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {LineLoader} from '../../elements';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ScrollGallery extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadHistoryData();
	}
	
	loadHistoryData(){
		try{
			this.setState({showLoader: true});
			API.get('v1/site/rand-gallery').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){}
	}
	
	render(){
		
		var settings = {
		  dots: false,
		  infinite: true,
		  speed: 500,
		  slidesToShow: 5,
		  slidesToScroll: 1
		};

		return(
			<div>
				<Slider {...settings}>
					{this.state.dataList.map((item,i)=>{
						return <img key={i}  className="gallery-holder" src={Api.ImageUrl + item.filepath} />;
					})
					}
			  </Slider>
			</div>									
		);
	}
	
}
export default ScrollGallery;
