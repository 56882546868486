import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';


class TopHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		
	}
	
	
	

	render(){
		
		
		return(
			<div className="rs-toolbar">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12">
							<div className="rs-toolbar-left">
								<div className="welcome-message">
									<i className="mdi mdi-map-marker-multiple"></i>
                                    <span>{getls('site','city')}</span> 
								</div>
								<div className="welcome-message">
									<i className="mdi mdi-phone"></i>
                                    <span><a href={"tel:"+getls('site','mobile')}>{getls('site','mobile')}</a></span> 
								</div>
								{/*<div className="welcome-message">
									<i className="mdi mdi-email"></i>
                                    <span><a href={"mailto:"+getls('site','email')}>{getls('site','email')}</a></span> 
								</div>*/}
							</div>
						</div>
						<div className="col-md-6">
							<div className="rs-toolbar-right">
								<div className="toolbar-share-icon">
									<ul>
										<li><a href={getls('site','facebook')} target="_blank" ><i className="mdi mdi-facebook"></i></a></li>
										<li><a href={getls('site','twitter')} target="_blank" ><i className="mdi mdi-twitter"></i></a></li>
										<li><a href={getls('site','gplus')} target="_blank" ><i className="mdi mdi-google-plus"></i></a></li>
											{/*<li><a href={getls('site','youtube')} target="_blank" ><i className="mdi mdi-youtube"></i></a></li>*/}
									</ul>
								</div>
								{/*<a className="hidden-xs rs-search" data-target=".search-modal" data-toggle="modal" href="#">
									<i className="fa fa-search"></i>
								</a>*/}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
}
export default TopHeader;
