import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader, NewsScroll} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, getAscSortOrder} from '../../../utils';
import {CircleSlowLoader} from '../../elements';
import './index.css';
import Gallery from 'react-grid-gallery';
import axios from 'axios';

import PageHeader from '../Page/PageHeader';

const styles = {
	imgContainer: {
		border: '1px solid #8e8b8b',
		padding: '7px',
		cursor: 'pointer',
	}
}

class GalleryList extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
			showLoader: true,
		}
	}
	
	componentDidMount(){
		this.loadGallery();
	}
	
	loadGallery(){
		try{
			this.setState({showLoader: true});
			var slug = this.props.match.params.slug;
			API.get('v1/site/gallery/'+slug).then(res=>{
				if(res['data'].status=='1') {
					this.setState({dataList: res['data'].data, showLoader: false});
				}
			});						
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	showGalery(){
		try{
			var rv = [];
			var d = this.state.dataList;
			d.sort(getAscSortOrder('displayorder'));
			d.map((item,i)=>{
				/*rv.push(<div className="item" >
					<img src={Api.ImageUrl + item.filepath} style={{width: '100%'}} />
				</div>);*/
				rv.push({src: Api.ImageUrl + item.filepath, 
					thumbnail: Api.ImageUrl + item.filepath, 
					thumbnailWidth: 320, 
					thumbnailHeight: 174,
					tags: [{value: item.filetitle}],
					caption: item.filetitle,
				});
			});
			return rv;
		}
		catch(error){ }
	}
	
	getField(fieldName){
		try{
			var d = this.state.dataList;
			return d[0][fieldName];
		}
		catch(error){ }
	}
	
	
		
	render(){
		
		
		return(

			<section>
				<PageHeader pageTitle="Gallery" />
			
				<div  className="container pb-50 mb-50 ">
				
					<Row>
						<Col md={12} >
							
							<div >
								<h3 className="mt-10 text-center">
								{this.getField('gallerytitle')}</h3>
							</div>
							<span style={{float: 'left', fontSize: '18px', marginTop: '-35px' }} >
								<Link to="/gallery" >
									<i className="ion-ios7-arrow-left"></i> Back &emsp;
								</Link>
							</span>
							<hr className="" />
						</Col>
					</Row>
					{this.state.showLoader ? 
						<Row>
							<Col md={12} >
								<div className="text-center" >
									<CircleSlowLoader />
								</div>
							</Col>
						</Row> : 
						<Row>
							<Col md={12} >
								<Gallery images={this.showGalery()} /> 
							</Col>
						</Row>
					}
				</div>
			</section>
		);
	}
	
}
export default GalleryList;
