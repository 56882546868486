import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {ToastsStore} from 'react-toasts';
import {PageLoader} from '../../elements';
import {getls, getlsItem, setls, setlsItem} from '../../../utils';
import API from '../../../utils/API';
import {classList, Api} from '../../../utils/Variables';

class ChangePassword extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Update Password',
			showLoader: false,
			dataList: [],
			dataView: [],
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	

	componentDidMount(){
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({ btnText: 'Updating..', showLoader: true});
			
			API.post('/v1/web/changepassword', $("#cl_frm_change_pass").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("cl_frm_change_pass").reset();
					ToastsStore.success(res['data'].message);
					this.setState({showLoader: false, btnText: 'Update Password'});
				}
				else{
					ToastsStore.error(res['data'].message);
					this.setState({showLoader: false, btnText: 'Update Password'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'Update Password'});
		}
	}
	
	

	render(){
		
			
		return(
			<React.Fragment>
			<form action="" method="post" encType="multipart/form-data" id="cl_frm_change_pass" onSubmit={this.handleFormSubmit} >
				
				<Row>
					<Col md={12} >
						<label>Current Password <span className="text-danger">*</span></label>
						<input type="password" className="form-control form-control-sm" required name="i__oldPassword" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={12} >
						<label>New Password <span className="text-danger">*</span></label>
						<input type="password" className="form-control form-control-sm" required name="i__newPassword" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={12} >
						<label>Confirm Password <span className="text-danger">*</span></label>
						<input type="password" className="form-control form-control-sm" required name="i__confirmPassword" />
					</Col>
				</Row>
				
				<Row className="mt-10" >
					<Col md={12} className="text-right" >
						<button type="submit" className="btn btn-success" disabled={this.state.btnText!='Update Password'} >
							{this.state.btnText}
						</button>
					</Col>
				</Row>
				
			</form>
			</React.Fragment>
		);
	}
	
}
export default ChangePassword;
