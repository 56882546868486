import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {LineLoader} from '../../elements';


class StaticData extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadHistoryData();
	}
	
	loadHistoryData(){
		try{
			this.setState({showLoader: true});
			API.get('v1/site/content/'+this.props.dataKey).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){}
	}
	
	render(){

		return(
			<div>
				{this.state.showLoader ? null : this.state.dataList.data_value}
			</div>									
		);
	}
	
}
export default StaticData;
