import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {LineLoader} from '../../elements';

import StaticData from './StaticData';

class Teachers extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__dataKey', 'home_teachers_list');
			form.append('i__active', '1');
			form.append('i__order', 'data_1');
			form.append('i__orderBy', 'ASC');
			
			API.post('v1/site/staticdata/list', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){}
	}
	
	render(){

		return(
			<div id="rs-team" className="rs-team blue-bg bg8 sec-spacer">
				<div className="blue-overlay"></div>
					<div className="container">
						<div className="sec-title mb-50 text-center">
							<h2><StaticData dataKey='home_teachers_heading' /></h2>      
							<p className="white-color"><StaticData dataKey='home_teachers_about' /></p>
						</div>
						
						<div className="row" >
						
							{this.state.dataList.map((item,i)=>{
								return <div className="col-md-3 team-item">
									<div className="team-img">
										<img src={Api.ImageUrl + item.data_value} alt={item.data_1} />
										<div className="normal-text">
											<h3 className="team-name">{item.data_1}</h3>
											<span className="subtitle">{item.data_2}</span>
										</div>
									</div>
									<div className="team-content">
										<div className="overly-border"></div>
										<div className="display-table">
											<div className="display-table-cell">
												<h3 className="team-name"><a >{item.data_1}</a></h3>
												<span className="team-title">{item.data_2}</span>
												<p className="team-desc">{item.data_3}</p>
												{/*<div className="team-social">
													<a href="#" className="social-icon"><i className="fa fa-facebook"></i></a>
													<a href="#" className="social-icon"><i className="fa fa-google-plus"></i></a>
													<a href="#" className="social-icon"><i className="fa fa-twitter"></i></a>
													<a href="#" className="social-icon"><i className="fa fa-pinterest-p"></i></a>
												</div>*/}
											</div>
										</div>
									</div>
								</div>;
							})
							}
								
						</div>
					
					</div>
				
			</div>
		);
	}
	
}
export default Teachers;
