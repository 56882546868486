 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, getlsItem, setls, setlsItem} from '../../../utils';
import API from '../../../utils/API';
import {Var} from '../../../utils/Variables';
import {Footer} from '../../elements';

class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			isRedirect: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{			
			this.setState({showLoader: true, btnText: 'Validating..'});
			API.post('v1/webadmin/login', $("#frmLogin").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					setlsItem('user_logged', 'YES');
					setlsItem('apikey', res['data'].data.apikey);
					setlsItem('admin_user_logged', 'YES');
					setls('user', res['data'].data);
					setls('company', res['data'].company);
					this.setState({isRedirect: true});
					window.location.href='';
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnText: 'Login'});
				}
			});			
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'Login'});
		}
	}
	
	render(){

		//if(this.state.isRedirect || getlsItem('user_logged')=='YES'){
		if(getlsItem('user_logged')=='YES'){
			return(<Redirect to={"/"+Var.AdminPath+"/app"} />);
		}
		
		return(
		<div className="cl__admin" >
			<div id="cl__core_cls_container" >
					<div className=" pt-20" >
					
					<Notifications />
					
						<div id="layoutAuthentication">
							<div id="layoutAuthentication_content">
								<main>
									<div className="container">
									{/*<div className="row justify-content-center">*/}
										<div className="row">
											<div className="col-lg-8" ></div>
											<div className="col-lg-4">
												<div className="card shadow-lg border-0 rounded-lg mt-5">
													<div className="card-body">
														<h5 className="text-center font-weight-light">Login</h5>
														<hr />
														<form action="" method="post" encType="multipart/form-data" id="frmLogin" onSubmit={this.handleFormSubmit} >
															<div className="form-group">
																<label className="small mb-1" for="inputEmailAddress">Email</label>
																<input className="form-control form-control-sm" id="inputEmailAddress" type="text" placeholder="Enter email id OR mobile no" required name="i__userName" />
															</div>
															<div className="form-group">
																<label className="small mb-1" for="inputPassword">Password</label>
																<input className="form-control form-control-sm" id="inputPassword" type="password" placeholder="Enter password" required name="i__password" />
															</div>
															<div className="form-group">
																<div className="custom-control custom-checkbox"><input className="custom-control-input" id="rememberPasswordCheck" type="checkbox" /><label className="custom-control-label" for="rememberPasswordCheck">&emsp;&emsp;Remember password</label></div>
															</div>
															<div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
																<a className="small" href="javascript:;">Forgot Password?</a>
																<button type="submit" className="btn btn-primary" disabled={this.state.btnText!='Login'} >
																	{this.state.btnText}
																</button>
															</div>
														</form>
													</div>
													<div className="card-footer text-center">
														<div className="small"><a href="javascript:;">Need an account? Sign up!</a></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</main>
							</div>
							{/*<div id="layoutAuthentication_footer">
								
								<Footer />
								
							</div>*/}
						</div>
					</div>
				</div>
			</div>
		);
	}
	
}
export default Login;
