import React, { Component } from 'react';
import './index.css';

class BarLoader extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		
	}
	
	
	

	render(){
		
		
		return(
			<div className="lds-facebook">
				<div></div>
				<div></div>
				<div></div>
			</div>
		);
	}
	
}
export default BarLoader;
