import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';
import LOGO from '../../../assets/img/Sri-Vaiyapuri-Vidyala-logo-color.png';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
	}
	
	componentDidMount(){
		 //window.addEventListener('scroll', this.handleWindowScroll);
	}
	
	handleLogOut(){
		
		if(!window.confirm('Do you want to logout?')){
			return;
		}
		setlsItem('admission_user_logged', 'no');
		setls('logged_user', '');
		this.setState({isRedirect:true});
		window.location.href = '';
	}
	
	handleFormSubmit(e){
		e.preventDefault();
	}
	
	
	getPageTitle = () => {
		let name;
		this.props.routes.map(prop => {			
		  if (prop.path === this.props.location.pathname) {
			name = prop.name;
		  }
		  return null;
		});
		return name;
	};

	render(){
		
		/* if(this.state.isRedirect){
			return(<Redirect to="/admission" />);
		} */
		 
		/* return(
			<Navbar fixed="top" className="my-top-nav" >
			  <Navbar.Brand href="javascript:;">
				<Link to="/app" >
					<img src={LOGO} className="brand-logo" />
				</Link>
			  </Navbar.Brand>
			  <Navbar.Toggle />
			  <Navbar.Collapse className="justify-content-end">
			
				  <a href="javascript:;" onClick={this.handleLogOut}>Logout</a>
			  </Navbar.Collapse>
			  
		  </Navbar>
		); */
		
		return(
			<div className="section bg-light py-10" >
				<div className="container" >
					<Row>
						<Col md={8} >
							<Link to="/" >
								<img src={LOGO} className="brand-logo" style={{maxWidth: '300px'}} />
							</Link>
						</Col>
						<Col md={4} >
							{getlsItem('admission_user_logged')=='YES' ?
								<>
									<div className="pull-right pt-15" >
										 <a href="javascript:;" onClick={this.handleLogOut}>Logout</a>
									</div>
								</>
							: null}
						</Col>
					</Row>
				</div>
			</div>
		);	
	}
	
}
export default Header;
