import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import NOIMAGE_WIDE from '../../../../assets/img/noimage-xxl.png';
import Select from 'react-select';
import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';
import HomePageMenu from './HomePageMenu';


const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	},
	removeBtn: {
	    position: 'absolute',
		background: 'red',
		color: '#fff',
		borderRadius: '50px',
		padding: '0px 4px',
		fontSize: '11px',
		border: '1px solid black',
	}
}

class HomePageWelcomeBanner extends Component{
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Save',
			showLoader: false,
			linkList: [],
			selectedImage: [],
			selectedFilename: '',
			selectedUrl: '',
			dataList: '',
		}
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleShowChange = this.handleShowChange.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			
			API.get('v1/webadmin/staticcontent/home_welcome_banner').then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, selectedUrl: res['data'].data.data_value});
				}
			});
		}
		catch(error){
			
		}
	}
	
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			});
			//console.log(url);
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(this.state.selectedUrl.length<1)
			{
				notify.show("Please select a banner",'error');
				return;
			}
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/webadmin/staticcontent/save', $("#frm_AddhmBan_1").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					
					this.setState({showLoader: false, btnSave: 'Save'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Save'});
				}
			});
		}
		catch(error){
			alert('Unexpected Error');
			this.setState({showLoader: false, btnSave: 'Save'});
		}
	}
	
	
	getValue(fieldName){
		try{
			var d = this.state.dataList;
			return d[fieldName];
		}
		catch(error){ return false; }
	}
	
	setValue(e){
		try{
			var d = this.state.dataList;
			d.data_value =  e.target.value;
			this.setState({dataList: d});
		}
		catch(error){ return true;}
	}
	
	checkDefault(fieldName, val){
		try{
			var d = this.state.dataList;
			var v = (d[fieldName]==val) ? true : false;
			return v;
		}
		catch(error){  return false; }
	}
	
	handleChange(e){
		try{
			var d = this.state.dataList;
			d.active_status = d.active_status=='1' ? '0' : '1';
			this.setState({dataList: d});
		}
		catch(error){}
	}
	
	handleShowChange(e){
		try{
			var d = this.state.dataList;
			d.data_2 = e.target.value;
			//console.log(e.target.value);
			this.setState({dataList: d});
			//console.log(this.state.dataList);
		}
		catch(error){}
	}
	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<HomePageMenu />
				
				<LineLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_AddhmBan_1" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__dataKey" value="home_welcome_banner" />
					<input type="hidden" name="i__dataValue" value={this.state.selectedUrl} />
					
					<Row className="mt-10" >
						<Col md={8} >	
							<label>Link to open on banner click<span className="text-danger"></span></label>
							<input type="text" name="i__data1" className="form-control form-control-sm"  placeholder="Click Link" defaultValue={this.getValue('data_1')} />
						</Col>
						<Col md={12} className="mt-20" >
							{/*<div className="float-lefts" >
								<label className="chk-container">Show on First time home page visit
								  <input type="radio"  name="i__data2" value="onetime" defaultChecked={this.checkDefault('data_2', 'onetime')} />
								  <span className="checkmark"></span>
								</label>
							</div>
							
							<div className="float-lefts" >
								<label className="chk-container">Show on Every time home page visit
								  <input type="radio"  name="i__data2" value="everytime" defaultChecked={this.checkDefault('data_2','everytime')}  />
								  <span className="checkmark"></span>
								</label>
							</div>*/}
							
							<label>
								<input type="radio"  name="i__data2" value="onetime" checked={this.checkDefault('data_2', 'onetime') ? 'checked' : ''} onChange={this.handleShowChange} />
								&emsp; Show on First time home page visit
							</label>
							<br />
							<label>
								<input type="radio"  name="i__data2" value="everytime" checked={this.checkDefault('data_2', 'everytime') ? 'checked' : ''} onChange={this.handleShowChange} />
								&emsp; Show on Every time home page visit
							</label>
							
						</Col>
					</Row>
					
					<Row className="mt-20" >
						<Col md={8} >
							<label>Banner Image<span className="required"></span></label>
							
							{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length > 4 ? 
								<div>
									<img src={Api.ImageUrl+this.state.selectedUrl} style={{width: '100%', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}} />
								</div>
							:
								<div>
									
									<img src={NOIMAGE_WIDE} style={{width: '100%', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}}  />
								</div>
							}
							
						</Col>
					</Row>
					
					
					<Row className="mt-10" >
						<Col md={6} >
							<hr />
							<div className="float-left" >
							{/*<label className="chk-container">Active
								  <input type="checkbox" name="i__active" defaultChecked={this.checkDefault('active_status','1')} />
								  <span className="checkmark"></span>
							</label>*/}
								<label><input type="checkbox" name="i__active" checked={this.checkDefault('active_status','1') ? 'checked' : ''} onChange={this.handleChange} /> Active </label> 
							</div>
							<div className="float-right" >
								<Button type="reset" variant="danger" size="sm" onClick={this.props.atClose} >
									Cancel 
								</Button>
								&nbsp;
								<Button type="submit" variant="success" size="sm" disabled={this.state.btnSave!='Save'} >
									{this.state.btnSave=='Save' ? 
									<span className="ion-checkmark"></span> : 
									<span className="ion-loading-a"></span>}
									&nbsp; {this.state.btnSave}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
				
				<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</React.Fragment>
		);
	}
	
}
export default HomePageWelcomeBanner;
