import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import {LineLoader, SiteSlider} from '../../elements';

import AboutUs from './AboutUs';
import Events from './Events';
import Announcements from './Announcements';
import Teachers from './Teachers';
import ScrollGallery from './ScrollGallery';
import WelcomeBanner from './WelcomeBanner';

class Dashboard extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showPopupModal: true,
		}
	}
	
	getDisplayForm(){
		
		
	}
	
	render(){

		return(
			<React.Fragment>
				
				<SiteSlider />
				
				<AboutUs />
				
				<div id="rs-latest-news" className="rs-latest-news sec-spacer">
					<div className="container">
						<div className="sec-title mb-50 text-center">
							<h2><span className="orange-color">UPCOMING EVENTS</span> & ANNOUNCEMENTS</h2>      
						</div>
						<div className="row">
							<div className="col-md-6">
								<Events />
							</div>
							<div className="col-md-6">
								<Announcements />
							</div>
						</div>
					</div>
				</div>
				
				
				<Teachers />
				
				<div className="mt-80" />
				
				<ScrollGallery />
				
				<WelcomeBanner />
				
			</React.Fragment>
		);
	}
	
}
export default Dashboard;
