import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table, Card } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase, getRandomInt} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';


class ContentEditor extends Component{
	constructor(props) {
		super(props);
		this.state = {
		  showAddSlider: false,
		  showEditSlider: false,
		  editData: [],
		  dataList: [],
		  dataView: [],
		  btnText: 'Update',
		  formId: 'frm_'+getRandomInt(),
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			
			API.get('v1/webadmin/staticcontent/'+this.props.dataKey).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data});
				}
			});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnText: 'Updating..'});
			API.post('v1/webadmin/staticcontent/save',$("#"+this.state.formId).serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, btnText: 'Update'});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, btnText: 'Update'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'Update'});
		}
	}
	
	getValue(){
		try{
			return this.state.dataList.data_value;
		}
		catch(error){}
	}
	
	setValue(e){
		try{
			var d = this.state.dataList;
			d.data_value =  e.target.value;
			this.setState({dataList: d});
		}
		catch(error){}
	}
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />

				<form action="" method="post" encType="multipart/form-data" id={this.state.formId} onSubmit={this.handleFormSubmit} >
					<input type="hidden" name="i__dataKey" value={this.props.dataKey} />
					<Row>
						<Col md={12} >
							<textarea className="form-control form-control-sm" name="i__dataValue" required rows={this.props.rowSize || "5"} value={this.getValue()} onChange={this.setValue.bind(this)} ></textarea>
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<div className="text-right mt-10" >
								<Button type="submit" variant="success" size="sm" disabled={this.state.btnText!='Update'} >
									{this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
				</form>
								
			</React.Fragment>
		);
	}
	
}
export default ContentEditor;
