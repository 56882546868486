import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import {Api, classList} from '../../../utils/Variables';
import API from '../../../utils/API';

import IMG_LENGTH from '../../../assets/img/noimage-length.png';
import IMG_SQUARE from '../../../assets/img/noimage-square.png';
import IMG_WIDE from '../../../assets/img/noimage-wide.png';

import ModalFileBrowser from '../Filemanager/ModalFileBrowser';

class ApplicationFilter extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showfileBrowser: false,
			btnText: 'View Data',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
	
	}
	
	
	getFieldValue(fieldName){
		try{
			
			var d = this.props.data;
			return d[fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnText: 'Fetching..'});
			API.post('v1/webadmin/onlineadmission/admission/filter', $("#frmAdmFilter").serialize()).then(res=>{
				if(res['data'].status=='1'){
					this.setState({showLoader: false, btnText: 'View Data'});
					
					if(this.props.afterFinish)
						this.props.afterFinish(res['data'].data);
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnText: 'View Data'});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false, btnText: 'View Data'});
		}
	}
	
	render(){

		return(
		<>
		
			<form action="" method="post" id="frmAdmFilter" encType="multipart/form-data"  onSubmit={this.handleFormSubmit} >
				<Row>
					<Col md={4} >
						<label>Class</label>
					</Col>
					<Col md={8} >
						<select name="i__class" className="form-control form-control-sm" >
							<option value=""> -Show All- </option>
							{classList.map((item,i)=>{
							return <option value={item.key} > {item.name} </option>;
							})
							}
						</select>
					</Col>
				</Row>	
				
				<Row className="mt-15" >
					<Col md={4} >
						<label>Community</label>
					</Col>
					<Col md={8} >
						<select name="i__community" className="form-control form-control-sm" >
							<option value=""> -Show All- </option>
							<option value="OC" > OC </option>
							<option value="BC" > BC </option>
							<option value="BCM" > BC-Muslim </option>
							<option value="MBC" > MBC </option>
							<option value="DNC" > DNC </option>
							<option value="SC" > SC </option>
							<option value="ST" > ST </option>
							<option value="Converted-to-christian" > Convert to Christianity from Scheduled Caste </option>
						</select>
					</Col>
				</Row>

				<Row className="mt-15" >
					<Col md={4} >
						<label>Date From</label>
					</Col>
					<Col md={8} >
						<input type="date" className="form-control form-control-sm" name="i__from" />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={4} >
						<label>Date To</label>
					</Col>
					<Col md={8} >
						<input type="date" className="form-control form-control-sm" name="i__to" />
					</Col>
				</Row>
				
				
				<Row className="mt-15" >
					<Col md={4} >
						<label>Status</label>
					</Col>
					<Col md={8} >
						<select name="i__status" className="form-control form-control-sm" >
							<option value=""> -Show All- </option>
							<option value="1" > Admitted </option>
							<option value="0"> Pending </option>
						</select>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={4} ></Col>
					<Col md={8} >
						<Button type="submit" size="sm" variant="success" block disabled={this.state.btnText!='View Data'} >
						{this.state.showLoader ? <i className="ion-loading-c" /> : null} {this.state.btnText}
						</Button>
					</Col>
				</Row>
				
			</form>
				
			</>
		);
	}
	
}
export default ApplicationFilter;
