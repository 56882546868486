import React, { Component, Suspense, lazy } from 'react';

import '../admin/assets/css/style1.scss';
import '../admin/assets/custom.css';


import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import {Helmet} from 'react-helmet';
import routes from '../admin';
import {Header, SideNav, Footer} from '../admin/elements';
import API from '../utils/API';
import {Api, Var} from '../utils/Variables';
import {setls, getlsItem, getls} from '../utils';
import Notifications, {notify} from 'react-notify-toast';
import nav from '../admin/_nav';

class AdminLayout extends Component{
	constructor(props){
		super(props);
		this.state = {
			siteData: [],
		}
	}
	
	componentDidMount(){
		
		//$("body").addClass("sb-nav-fixed" );
	}
	
	render(){
			
		return(
		<div className="cl__admin" >
			<div id="cl__core_cls_container" >
				<div className="sb-nav-fixed" > 
				
				<Helmet>
					<title>Admin Panel - {Var.Title} </title>
				</Helmet>
					
					<Notifications />
					
					<Header 
					routes={routes}
					{...this.props}
					/>
					
					<div id="layoutSidenav">
						
						<SideNav nav={nav} role={getls('user','role')} />
						
						 <div id="layoutSidenav_content">
							<main>
								<div className="container-fluid pt-20 pb-20">
							
								<Switch>
									{routes.map((page,key)=>(
										<Route exact path={page.path}  component={page.component} key={key} />
									))}
								</Switch>	
								
								</div>							
							</main>
							
							<Footer />
							
						</div>
						
						
					</div>
									
				</div>
				</div>
			</div>
		);
	}
	
}
export default AdminLayout;