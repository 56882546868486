import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {LineLoader, SiteSlider, CircleSlowLoader} from '../../elements';

import Page from '../Page';
import PageHeader from '../Page/PageHeader';

import IMG_404 from '../../../assets/img/404.jpg';

class NotFound extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			pageUrl: '',
			pageData: [],
		}
	}
	
	componentDidMount(){
		
		this.state.showLoader = true;
		this.state.pageUrl = this.props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
	}
	
	componentWillReceiveProps(props){
		
		this.state.pageUrl = props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
		//this.forceUpdate();
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader:true, pageData: []});
			API.get('v1/site/page/'+this.state.pageUrl).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].data, showLoader:false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			var d = this.state.pageData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	render(){

		return(
			<React.Fragment>
				
				{this.state.showLoader ? <CircleSlowLoader loading={true} /> : 
				 <>
					{Object.keys(this.state.pageData).length>0 ? <Page data={this.state.pageData} /> :
						<>
							<PageHeader pageTitle="Not Found" />
							
							<section>
								<div className="container mt-100 py-10 px-10 " >
									<Row>	
										<Col md={12} >
											<div className="text-center mb-50" >
											  <img src={IMG_404} alt="404"   className="img-fluid" />
											  <br />
											  <a href="javascript:history.back()"  className="btn btn-danger rounded-pill"><i data-feather="arrow-left"></i> Go back</a>
										  </div>
										</Col>
									</Row>
								</div>
							</section>
							
						</>
					}
				
				</>
				}
				
			
			</React.Fragment>
		);
	}
	
}
export default NotFound;
