import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import Menu from './menu';

class MenuOrder extends Component{
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnSave: 'Update Menu Order',
			treeData: [],
			menuList: [],
		}
		this.handleTreeOnChange = this.handleTreeOnChange.bind(this);
		this.handleUpdate_Menu_Order_Click = this.handleUpdate_Menu_Order_Click.bind(this);
	}
	
	componentDidMount(){
		
		this.loadMenus();
	}
	
	loadMenus(){
		try{
			
			this.setState({showLoader: true, treeData: []});
			API.get('v1/webadmin/menus/1').then(res => {
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, menuList: res['data'].data, treeData: res['data'].data});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleTreeOnChange(treedata){
		try{
			
			this.setState({treeData: treedata});
			
			console.log(treedata);
		}
		catch(error){
			
		}
	}
	
	
	handleUpdate_Menu_Order_Click(){
		
		try{
			this.setState({showLoader: true, btnSave: 'Updating..'});
			var d = this.state.treeData;
			API.post('v1/webadmin/menus/updateorder',d).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnSave: 'Update Menu Order'});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Update Menu Order'});
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}
	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				<Menu />
				
				<Row>
					<Col md={9} >
					
						 <div style={{ height: 'calc(100vh)' }}>
							<SortableTree
							  treeData={this.state.treeData}
							  onChange={this.handleTreeOnChange}
							  
							/>
						  </div>
						  
					</Col>
					
					<Col md={3} >
						<Button type="button" size="sm" variant="success" onClick={this.handleUpdate_Menu_Order_Click} disabled={this.state.btnSave!='Update Menu Order'} >
							{this.state.btnSave}
						</Button>	
					</Col>
				</Row>
				
			</React.Fragment>
		);
	}
	
}
export default MenuOrder;
