import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import CKEditor from 'ckeditor4-react';
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import NOIMAGE_WIDE from '../../../../assets/img/noimage-xxl.png';
import Select from 'react-select';
import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';

class EditAnnouncement extends Component{
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update',
			editData: this.props.editData,
			showInTop: false,
			pageData: '',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handlePageContentChange = this.handlePageContentChange.bind(this);
	}
	
	componentDidMount(){
		
		this.setState({showInTop: this.props.editData.showintop=='1', pageData: this.props.editData.content});
		
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnSave: 'Updating..', showLoader: true});
			
			API.post('v1/webadmin/news/update/', $("#frm_edit_news").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update', showLoader: false});
					
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update', showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	setField(fieldName, e){
		try{
			
			var d = this.state.editData;
			d[fieldName] = e.target.value;
			this.setState({editData: d});
		}
		catch(error){ }
	}
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_edit_news" onSubmit={this.handleFormSubmit} >
		
				<input type="hidden" name="i__id" value={this.getField('id')} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				<input type="hidden" name="i__type" value="announcement" />
				<input type="hidden" name="i__active" value="1" />
				<input type="hidden" name="i__pageData" value={this.state.pageData} />
				
					
					
					<Row>
						<Col md={12} >
							<label>Announcement Title <span className="text-danger">*</span></label>
							<input type="text" name="i__title" className="form-control form-control-sm" required  value={this.getField('title')} onChange={this.setField.bind(this,'title')} />
						</Col>
					</Row>
					
					
					<Row className="mt-15" >
					<Col md={12} >
						<label>Announcement Content <span className="text-danger">*</span></label>
						{this.state.showLoader ? null : <CKEditor 
						name='add_even_ad_content' 
						config={{ 
						toolbar: [
							[ 'Bold', 'Italic' ],
							[ 'Cut', 'Copy' ],
							['Styles', 'Format', 'FontSize', 'TextColor', 'BGColor' ],
							[ 'NumberedList', 'BulletedList', 'JustifyLeft', 'JustifyCenter', 'JustifyBlock', 'JustifyRight', 'Blockquote' ],
							['Link', 'Image', 'Source', 'Maximize']
						],	
						height: '300', 
						startupShowBorders: 'false', 
						allowedContent: true, 
						extraAllowedContent: '*(*);*{*}' }} 
						data={this.getField('content')} 
						onChange={this.onContentEditorChange} />
						}
					</Col>
				</Row>
					
				<Row className="mt-15" >
					<Col md={12} >
						<label>Announcement Link <span className="required"></span></label>
						<input type="url" name="i__link" className="form-control form-control-sm" defaultValue={this.getField('link')} />
					</Col>
				</Row>
				
				
				<Row className="mt-15" >
					<Col md={4} >
						<label>Expired On <span className="required"></span></label>
						<input type="date" name="i__expired" className="form-control form-control-sm"   defaultValue={this.getField('expireon')} />
					</Col>
					<Col md={4} className=" pt-35" >
						<label className="chk-container">Show in top scrolling
						  <input type="checkbox" name="i__showInTop" checked={this.state.showInTop} onChange={()=>{this.setState({showInTop: !this.state.showInTop})}} />
						  <span className="checkmark"></span>
						</label>
						
					</Col>
					<Col md={4} className=" pt-35" >
						<div className="float-right" >
							<Button type="reset" variant="danger" size="sm" onClick={this.props.atClose} >
								Cancel 
							</Button>
							&nbsp;
							<Button type="submit" variant="success" size="sm" disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave=='Update' ? 
								<span className="ion-checkmark"></span> : 
								<span className="ion-loading-a"></span>}
								&nbsp; {this.state.btnSave}
							</Button>
						</div>
					</Col>
				</Row>
					
				</form>
				
				<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</React.Fragment>
		);
	}
	
}
export default EditAnnouncement;
