import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase, groupByMultiple} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';

import AddGallery from './AddGallery';
import EditGallery from './EditGallery';

class Gallery extends Component{
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
		}
		this.handlePageSearch = this.handlePageSearch.bind(this);
		this.loadData = this.loadData.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true, dataList: [], dataView: [], showAddModal: false, showEditModal: false});
			API.get('v1//webadmin/gallery/list').then(res=>{
				if(res['data'].status=='1') {
					var d = groupByMultiple(res['data'].data, function(obj){
						return[obj.refkey];
					});
					this.setState({dataList: d, dataView: d, showLoader: false});
				}
				else {
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handlePageSearch(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj[0].gallerytitle).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){ }
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Page?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			this.setState({showLoader: true});
			API.get('v1/webadmin/gallery/remove/'+item[0].refkey).then(res=>{
				if(res['data'].status=='1') {
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});*/
					d.splice(i,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else {
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleGalleryEdit(item){
		this.setState({editData: item, showEditModal: true});
	}
	
	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				
				<Row>
					<Col md={9} >
						<div className="input-group" >
							<input type="text" className="form-control form-control-sm" placeholder="Search by Title..." onChange={this.handlePageSearch} />
							<div className="input-group-append">
								<span className="input-group-text"><i className="ion-android-search" /></span>
							</div>
						</div>
					</Col>
					<Col md={3} >
						<div className="text-right" >
							<ButtonGroup >
								<Button type="button" size="sm"  variant="outline-secondary" onClick={()=>{this.setState({showAddSlider: true})}} >
									<span className="ion-android-add"></span> Add Gallery
								</Button>
								
								<Button variant="outline-secondary" size="sm" onClick={this.loadData} >
									<i className="ion-ios7-reload" /> Refresh
								</Button>
							</ButtonGroup >
						</div>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						
							{this.state.showLoader ? <center><Loader type="dot" /></center> : 
								<Table hover bordered size="sm cl__table__xs" >
									<thead>
										<tr>
											<th width="80" >S.No</th>
											<th>Gallery Title</th>
											<th>Images</th>
											<th width="120" >Manage</th>
										</tr>
									</thead>
									<tbody>
									{this.state.dataView.map((item,i)=>{
										return <tr key={i} >
											<td>{sno++}</td>
											<td>{item[0].gallerytitle}</td>
											<td>{Object.keys(item).length} Image(s)</td>
											<td align="center" >
												<Button type="button" size="sm" variant="info" title="Edit" onClick={this.handleGalleryEdit.bind(this,item)} >
													<span className="ion-edit"></span>
												</Button>
												&nbsp;
												<Button type="button" size="sm" variant="danger" title="Delete" onClick={this.handleRemove.bind(this,item,i)} >
													<span className="ion-close"></span>
												</Button>
											</td>
										</tr>;
									})
									}
									</tbody>
								</Table>
							}
					</Col>
				</Row>
				
				<Modal show={this.state.showAddSlider} onHide={()=>{this.setState({showAddSlider:false})}} backdrop="static" size="xl" className="" >
					<Modal.Header closeButton>
						Add Gallery
					</Modal.Header>
					<Modal.Body>
						<AddGallery  atFinish={this.loadData} />	
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditModal} onHide={()=>{this.setState({showEditModal:false})}} backdrop="static" size="xl" className="" >
					<Modal.Header closeButton>
						Edit Gallery
					</Modal.Header>
					<Modal.Body>
						<EditGallery  editData={this.state.editData} atFinish={this.loadData}  />
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default Gallery;
