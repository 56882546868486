import React, { Component, Suspense, lazy } from 'react';

import '../assets/css/rsmenu-main.css';
import '../assets/css/rsmenu-transitions.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import '../assets/custom.css';

import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import routes from '../views';
import {Header, SideNav, Footer, TopHeader, CircleSlowLoader, AdminHeader} from '../views/elements';
import API from '../utils/API';
import {Api} from '../utils/Variables';
import {ProgressBar } from 'react-bootstrap';
import {setls, getlsItem, getls} from '../utils';
import Notifications, {notify} from 'react-notify-toast';
import nav from '../_nav';

class SiteLayout extends Component{
	constructor(props){
		super(props);
		this.state = {
			siteData: [],
			showLoader: false,
			uploadPercentage: 0,
		}
	}
	
	componentDidMount(){
		
		this.loadSiteInfo();
	}
	
	loadSiteInfo(){
		try{
			
			this.setState({showLoader: true});
			
			var $this = this;
				const config = {
				onUploadProgress: function(progressEvent) {
				  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
				  $this.setState({uploadPercentage: percentCompleted});
				}
			  }
			  
			API.get('v1/site/info').then(res=>{
				if(res['data'].status=='1'){
					
					setls('site',res['data'].data, config);
					this.setState({showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
			
		return(
			<div className="home5" > 
				
				<AdminHeader />
				
				{this.state.showLoader ?
					<center  className="pt-50" >
						
						<CircleSlowLoader /> 
						
						{/*<div style={{width: '350px'}} className="pt-50">
							<ProgressBar striped variant="info" now={this.state.uploadPercentage} animated  />
						</div>*/}
						 
					</center>
					:
				<>
					<div className="full-width-header">
						
						<TopHeader />
						
						<Header />
					
					</div>
					
					
						<div style={{minHeight: 'calc(100vh - 150px)'}} >
						
							<Switch>
								{routes.map((page,key)=>(
									<Route exact path={page.path}  component={page.component} key={key} />
								))}
							</Switch>	
							
						</div>
								
					<Footer />
					
				</>}
								
			</div>
		);
	}
	
}
export default SiteLayout;