import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import NOIMAGE_WIDE from '../../../../assets/img/noimage-xxl.png';
import Select from 'react-select';
import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';


const styles = {
	courseBtn: {
	    position: 'fixed',
		top: '51px',
		right: '0',
		zIndex: '99',
	},
	removeBtn: {
	    position: 'absolute',
		background: 'red',
		color: '#fff',
		borderRadius: '50px',
		padding: '0px 4px',
		fontSize: '11px',
		border: '1px solid black',
	}
}

class AddGallery extends Component{
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			btnUpload: 'Upload',
			showFileBrowser: false,
			fileList: [],
			fileView: [],
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleBtnClick = this.handleBtnClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleFileBrowserClick(files){
		try{
			var d = this.state.fileList;
			files.map((item,i)=>{
				var url = item.data.path +'/'+ item.data.name;
				url= url.replace(/\/\//g, "/");
				d.push({
					url: Api.ImageUrl+ url,
					filepath: url,
					name: item.data.name,
					filetitle: item.data.name,
					displayorder: '',
				});
			});
			this.setState({fileList: d});
		}
		catch(error){ }
	}
	
	handleRemoveImageClick(index){
		try{			
			var d = this.state.fileList;
			d.splice(index,1);
			this.setState({fileList: d});
		}
		catch(error){ }
	}
	
	handleBtnClick(){
		this.setState({showFileBrowser: true});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			if(Object.keys(this.state.fileList).length<1) {
				notify.show('Please select Images to update Gallery.!');
				return;
			}
			this.setState({showLoader: true, btnUpload: 'Uploading..'});
			API.post('v1/webadmin/gallery/save',$("#frm_my_gal_add").serialize()).then(res=>{
				if(res['data'].status=='1') {
					notify.show(res['data'].message,'success');
					document.getElementById("frm_my_gal_add").reset();
					this.setState({showLoader: false, btnUpload: 'Upload', fileList: []});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else {
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnUpload: 'Upload'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnUpload: 'Upload'});
		}
	}
	
	updateTitle(index,e){
		try{			
			var d = this.state.fileList;
			d[index]['filetitle'] = e.target.value;
			this.setState({fileList: d});			
		}
		catch(error){ }
	}
	
	updateImageOrder(index,e){
		try{			
			var d = this.state.fileList;
			d[index]['displayorder'] = e.target.value;
			this.setState({fileList: d});			
		}
		catch(error){ }
	}
	
	getFieldValue(fieldName, index){
		try{
			var d = this.state.fileList;
			//console.log(d[index]);
			return d[index][fieldName];
		}
		catch(error){ }
	}
	
	
	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_my_gal_add" onSubmit={this.handleFormSubmit} >
				
				<input type="hidden" name="i__userId" value={getls('user', 'id')} />
				<input type="hidden" name="i__files" value={JSON.stringify(this.state.fileList)} />
				
					<Row>
						<Col md={9} >
							<label>Gallery Title <span className="text-danger">*</span> </label>
							<input type="text" name="i__title" className="form-control form-control-sm" required />
						</Col>
						<Col md={3} >
							<label>Display Order<span className="required"></span> </label>
							<input type="number" name="i__galleryOrder" className="form-control form-control-sm" />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>Gallery Images</label>
							<Button type="button" size="sm" variant="info" onClick={this.handleBtnClick}  >
								Select Images
							</Button>
						</Col>
					</Row>
					
					
						<Row className="mt-10" style={{height: 'calc(100vh - 260px)', overflowY: 'scroll' }} >
							<Col md={12} >
								<Table width="100%" className="tbl" >
									<thead >
										<tr>
											<th width="80">S.No</th>
											<th>Image</th>
											<th>Title</th>
											<th>Order</th>
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
									{this.state.fileList.map((item,i)=>{
										return <tr>
											<td align="centeR" >{sno++}</td>
											<td>
												{/*<a href="javascript:;" style={styles.removeBtn} >
														<i className="ion-android-close"></i>
												</a>*/}
												<img src={item.url} style={{maxHeight: '150px', border:'1px solid grey'}}/>
											</td>
											<td align="center"  >
												<textarea rows="3" onChange={this.updateTitle.bind(this,i)} className="form-control form-control-sm" >{this.getFieldValue('filetitle', i)}</textarea>
											</td>
											<td>
												<input type="number" style={{width: '100px'}} onChange={this.updateImageOrder.bind(this,i)}   value={this.getFieldValue('displayorder', i)}  />
											</td>
											<td align="center" >
												<Button type="button" size="sm" variant="danger"  onClick={this.handleRemoveImageClick.bind(this,i)}>
													<i className="ion-android-close"></i>
												</Button>
											</td>
										</tr>;
									})
									}
									</tbody>
								</Table>
							</Col>
						</Row>
						
					<Row className="mt-10" >
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" color="success" size="sm " disable={this.state.btnUpload!='Upload'} >
									{this.state.btnUpload=='Upload' ? '' : 
										<span className="ion-loading-a" ></span>} &nbsp;
									{this.state.btnUpload}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
				
				<ModalFileBrowser isOpen={this.state.showFileBrowser} imageOnly multiple  
					atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}}
					fileBrowserClick={this.handleFileBrowserClick} >
						
				</ModalFileBrowser>		
				
			</React.Fragment>
		);
	}
	
}
export default AddGallery;
