import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';
import LOGO from '../../../assets/img/Sri-Vaiyapuri-Vidyala-logo-color.png';

class EmptyHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
	}
	
	componentDidMount(){
		 //window.addEventListener('scroll', this.handleWindowScroll);
	}
	
	handleLogOut(){
		
		if(!window.confirm('Do you want to logout?')){
			return;
		}
		setlsItem('user_logged', 'no');
		setls('logged_user', '');
		this.setState({isRedirect:true});
		window.location.href = '';
	}
	
	handleFormSubmit(e){
		e.preventDefault();
	}
	
	
	getPageTitle = () => {
		let name;
		this.props.routes.map(prop => {			
		  if (prop.path === this.props.location.pathname) {
			name = prop.name;
		  }
		  return null;
		});
		return name;
	};

	render(){
		
		/*if(getlsItem('user_logged')!='YES'){
			return(<Redirect to="/" />);
		}
		*/
		
		return(
			<Navbar fixed="top" className="my-top-nav" >
			  <Navbar.Brand href="javascript:;">
				<Link to="/" >
					<img src={LOGO} className="brand-logo" />
				</Link>
			  </Navbar.Brand>
			  <Navbar.Toggle />
			  <Navbar.Collapse className="justify-content-end">
			  
			  </Navbar.Collapse>
			  
		  </Navbar>
		);
	}
	
}
export default EmptyHeader;
