import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Api} from '../../../utils/Variables';
import API from '../../../utils/API';

import CircleLoader from '../CircleLoader';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import SliderItem from './SliderItem';

class SiteSlider extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}		
	}
	
	componentDidMount(){
		
		this.loadSliders();
	}
	
	loadSliders()
	{
		try{
			this.setState({showLoader: true});
			API.get('v1/site/sliders/').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		if(this.state.showLoader){
			return(
				<div className="py-20" className="slider_placeholder" style={{height: 'calc(100vh - 180px)',paddingTop: '10%', backgroundColor: '#888888'}} >
					<center> 
						<CircleLoader />
					</center> 
				</div>
			);
		}
		
		
		return(
			  <AwesomeSlider style={{height: '560px'}} >
				{this.state.dataList.map((item,i)=>{
					return <div data-src={Api.ImageUrl + item.url}  >
						<div className="myslider-title-container" ></div>
							<div className="slide--subheadline">{item.title}</div>
							<div className="slide--headline">{item.content}</div>
						
					</div>;
				})
				}
			  </AwesomeSlider>
		);
	}
	
}
export default SiteSlider;
