import Dashboard from './pages/Dashboard';
import Gallery from './pages/Gallery';
import GalleryList from './pages/Gallery/GalleryList';
import NotFound from './pages/NotFound';



const pageList = [
	{ name: 'Dashboard', path: '/', component: Dashboard },
	{ name: 'Gallery', path: '/gallery', component: Gallery },
	{ name: 'Gallery', path: '/gallery/:slug', component: GalleryList },
	{ name: 'Not Found', path: '*', component: NotFound },
	
];

export default pageList;