import axios from 'axios';
import {Api} from './Variables';
import {getls, getlsItem} from './';

export default axios.create({
  baseURL: Api.Url,
	headers: {
		"Api-Token": getlsItem('apikey')
	}
  /*timeout: 1000,*/
 /* headers: {
	  'Content-Type': 'multipart/form-data',
	'PARAM_HEADER': 'eyJ0eXAiOiJKV1QiLC'
  }*/
});