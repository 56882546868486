import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button, Form, Table} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import ReactFileReader from 'react-file-reader';
import {notify} from 'react-notify-toast';
import {ToastsStore} from 'react-toasts';
import {PageLoader} from '../../elements';
import {getls, getlsItem, numberToMonth, momentDate} from '../../../utils';
import {classList, Api} from '../../../utils/Variables';
import API from '../../../utils/API';

class FilledApplications extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save Application',
			showLoader: false,
			dataList: [],
			dataView: [],
		}
		
		this.loadApplications = this.loadApplications.bind(this);
		this.handleApplictionDownloda = this.handleApplictionDownloda.bind(this);
	}
	
	componentDidMount(){
		
		this.loadApplications();
	}
	
	loadApplications(){
		try{
			this.setState({dataList: [], dataView: [], showLoader: true});
			
			API.post('/v1/web/applications').then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});
				}
				else{
					ToastsStore.error(res['data'].message);
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getAppliedFor(k){
		var d = classList.filter(function(obj){
			return (obj.key==k);
		});
		
		return d[0].name;
	}
	
	handleApplictionDownloda(no){
		try{
			this.setState({showLoader: true});
			var filePath = Api.Url +'v1/pdfapp/'+no;
			var a = document.createElement('A');
			a.href = filePath;
			a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a); 
			
			 /* var form = document.createElement("form");
			var element1 = document.createElement("input"); 
			var element2 = document.createElement("input");  

			form.method = "post";
			form.action = filePath;   
			form.target="_blank";

			element1.value=no;
			element1.name="i__id";
			form.appendChild(element1);  

			document.body.appendChild(form);
			
			form.submit(); */
			
			this.setState({showLoader: false});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		if(getlsItem('admission_user_logged')!='YES'){
			return(<Redirect to="/admission/login" />);
		}
		
		let sno=1;
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
				
				<Container className="pt-20"  >
					<Row>
						<Col md={{span: 10, offset:1 }} >
													
							<Card>
								<Card.Body>
									<Row>
										<Col md={12} >
											
											<h5 className="text-left" >Filled Applications
												<span className="pull-right" >
													<Link to="/admission/home" style={{fontSize: '13px'}} >
														<i className="ion-arrow-left-c" /> &nbsp; Go Back
													</Link>
												</span>
											</h5>
											<hr />
											
										</Col>
										
										<Col md={12} >
											<div className="table-responsive" >
												<Table  bordered  hover size="xs" >
													<thead>
														<tr>
															<th>S.No</th>
															<th>Application No</th>
															<th>Applied On</th>
															<th>Applied For</th>
															<th>Student Name</th>
															<th>Mobile</th>
															<th>Download</th>
														</tr>
													</thead>
													<tbody>
													{this.state.dataView.map((item,i)=>{
														return <tr key={i} >
															<td>{sno++}</td>
															<td>{item.application_no}</td>
															<td>{momentDate(item.idate,'DD-MMM-YYYY')}</td>
															<td>{this.getAppliedFor(item.apcation_for)}</td>
															<td>{item.student_name}</td>
															<td>{item.mobile}</td>
															<td align="center" onClick={this.handleApplictionDownloda.bind(this,item.id)} >
																<Button type="button" variant="info" size="sm" title="Download" >
																	<i className="ion-arrow-down-c" />
																</Button>
															</td>
														</tr>;
													})														
													}
													</tbody>
												</Table>
											</div>
										</Col>
										
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			
			</React.Fragment>
		);
	}
	
}
export default FilledApplications;
