import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table, Card, Collapse } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Pagination from "react-js-pagination";
import CKEditor from 'ckeditor4-react';

import {getls, upperCase, momentDate, makeUrl} from '../../../../utils';
import API from '../../../../utils/API';
import {Api, Var} from '../../../../utils/Variables';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';

import NOIMAGE_WIDE from '../../../../assets/img/noimage-square.png';


const styles = {
	imgCloseBtn: {
		position: 'absolute',
		right: '30px',
		top: '115px',
		background: '#ff2828',
		color: '#fff',
		padding: '0px 3px',
		borderRadius: '100px',
		fontSize: '11px',
		cursor: 'pointer',
	}
}

class EditEvent extends Component{
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update',
		  showStudentModal: false,
		  showFileManager: false,
		  showEditModal: false,
		  isActive: true,
		  showInTop: false,
		  editSlug: false,
		  showInUp: false,
		  pageData: '',
		  inTab: false,
		  inFeatureImage: false,
		  selectedFilename: '',
		  selectedUrl: '',
		  pageUrl: '',
		  editData: [],
		}
		
		this.handlePageContentChange = this.handlePageContentChange.bind(this);
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);	
		this.removeSelectedFeatureImage = this.removeSelectedFeatureImage.bind(this);	
		this.handleTitleChange = this.handleTitleChange.bind(this);	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);	
		this.handleJoditChange = this.handleJoditChange.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			var id = this.props.match.params.id;
			var slug = this.props.match.params.slug;
			API.get('v1/webadmin/news/single/'+id).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data[0];
					this.setState({
						editData: d, 
						pageData: d.content, 
						showInTop: d.showintop=='1', 
						showInUp: d.showinupcoming=='1', 
						isActive: d.activestatus=='1', 
						inFeatureImage: (d.featureimage!='' && d.featureimage!=null) ? true : false,
						selectedFilename: '',
						selectedUrl: d.featureimage!='' && d.featureimage!=null && d.featureimage.length>3 ? d.featureimage : '',
						showLoader: false
					});
					
					console.log(this.state);
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	removeSelectedFeatureImage(){
		this.setState({ selectedFilename: '',selectedUrl: '' });
	}
	
	
	handleTitleChange(e){
		try{
			var str = e.target.value;
			var newString = str.replace(/[^A-Z0-9]/ig, "-");
			this.setState({pageUrl: newString.toLowerCase()});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnSave: 'Updating..', showLoader: true});
			
			API.post('v1/webadmin/news/update/', $("#frmEdit_News").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update', showLoader: false});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update', showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			var d = this.state.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	handleJoditChange(content){
		
		this.setState({pageData: content});
	}
	
	
	render(){

		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				<Row className="border-bottom pb-10 mb-10" >
					<Col md={9} >
						
					</Col>
					<Col md={3} >
						<div className="text-right" >
							<ButtonGroup >
								<Link to={"/"+Var.AdminPath+"/app/cms/events"} className="btn btn-outline-secondary btn-sm" >
									<span className="ion-ios7-arrow-thin-left"></span> Back to Events
								</Link>
							</ButtonGroup >
						</div>
					</Col>
					
				</Row>
								
				<form action="" method="post" encType="multipart/form-data" id="frmEdit_News" onSubmit={this.handleFormSubmit} >
			
						<input type="hidden" name="i__type" value="events" />
						<input type="hidden" name="i__id" value={this.getField('id')} />

				
					<Row>
						<Col md={9} >
					
							<Row>
								<Col md={12} >
									<label>Title <span className="text-danger">*</span></label>
									<input type="text" name="i__title" className="form-control form-control-sm" required placeholder="Title" onChange={this.handleTitleChange} defaultValue={this.getField('title')} />
								</Col>
							</Row>
							
							<Row className="mt-20" >
								<Col md={12} >
									<label>Page Content <span className="text-danger">*</span>
										<div style={{position: 'absolute',top: '-1px',left: '150px'}} >											
											<Button variant="info"size="sm" onClick={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
												Open Filemanager
											</Button>
										</div>
									</label>
									
									<div className="page-content-editor" >
									{this.state.showLoader ? null : 
										<CKEditor
											data={this.state.pageData}
											config={{
												height: '500px'
											}}
											onChange={this.onContentEditorChange}
										/>
									}
									</div>
								</Col>
							</Row>
						
						</Col>
						
						<Col md={3} >
							
							<Card>
								<Card.Body>
										
									<Row>
										<Col md={12} >
											<div className="float-left" >
												<label className="chk-container">Show in Upcoming Event
												{this.state.showInUp ? 
												<input type="checkbox" name="i__showInUp" value="1" defaultChecked={true} onChange={()=>{this.setState({showInUp: !this.state.showInUp})}}  />
												: <input type="checkbox" name="i__showInUp" value="1" defaultChecked={true} onChange={()=>{this.setState({showInUp: !this.state.showInUp})}}  />
												}
												  <span className="checkmark"></span>
												</label>
											</div>
										</Col>										
									</Row>
									
									<Row>
										<Col md={12}> <hr /> </Col>
									</Row>
									
									<Row className="mt-20" >
										<Col md={12} >
											<div className="float-left" >
												<label className="chk-container">Active Status
												  <input type="checkbox" name="i__active" checked={this.state.isActive===true} onChange={()=>{this.setState({isActive: !this.state.isActive})}}  />
												  <span className="checkmark"></span>
												</label>
											</div>
											<div className="float-right" >
												<Button type="submit" size="sm" variant="primary" >
													{this.state.btnSave=='Update' ? 
														<span className="ion-android-checkmark" ></span> :
														<span className="ion-loading-a" ></span>} &nbsp;
													{this.state.btnSave}
												</Button>
											</div>
											
										</Col>
										
									</Row>
									
								</Card.Body>
							</Card>
							
							<Card className="mt-15" >
								<Card.Body>
									<Row>
										<Col md={12} >
											<label>Event Start Date</label>
											<input type="date" name="i__evtStartDt" className="form-control form-control-sm" defaultValue={this.getField('eventstartdate')} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<label>Event End Date</label>
											<input type="date" name="i__evtEndDt" className="form-control form-control-sm" defaultValue={this.getField('eventenddate')} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<label>Event Venue</label>
											<input type="text" name="i__evtVenue" className="form-control form-control-sm" defaultValue={this.getField('eventvenue')} />
										</Col>
									</Row>
								</Card.Body>
							</Card>
							
							
							<Card className="mt-15" >
							<Card.Body>
								<div className="float-left" >
									<label className="chk-container">Feature Image
									{this.state.inFeatureImage ? <input type="checkbox" name="i__inFeatureImage" defaultChecked={true} onChange={()=>{this.setState({inFeatureImage: !this.state.inFeatureImage})}}  />
										: <input type="checkbox" name="i__inFeatureImage" defaultChecked={false} onChange={()=>{this.setState({inFeatureImage: !this.state.inFeatureImage})}}  />
									}
									  <span className="checkmark"></span>
									</label>
								</div>
							</Card.Body>
								<hr style={{margin:'0px'}} />
							<Card.Body>
								<div style={{display: this.state.inFeatureImage ? 'table' : 'none'}}>
									<div style={{marginTop: '15px', marginBottom: '5px' }} >
										<img src={this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length>3 ? Api.ImageUrl+this.state.selectedUrl : NOIMAGE_WIDE} style={{width: '100%'}} />
											{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length>3  ?
												<div style={styles.imgCloseBtn} onClick={this.removeSelectedFeatureImage} >
													<span className="ion-android-close"></span>
												</div>
											: null}
									</div>
									<hr style={{marginBottom:'10px'}} />
									<div className="text-center mb-10" >
									<ModalFileBrowser isOpen={this.state.showFileManager} 
													atClose={()=>{this.setState({showFileManager: !this.state.showFileManager})}}
													fileBrowserClick={this.handleFileBrowserClick}
												>
									<Button type="button" size="sm" color="secondary" block onClick={()=>{this.setState({showFileManager: !this.state.showFileManager})}} >
										<span className="ion-android-folder"></span> Browse Image
									</Button>
									</ModalFileBrowser>
									</div>
								</div>
							</Card.Body>
						</Card>
							
						</Col>
						
					</Row>
					
					<input type="hidden" name="i__pageData" value={this.state.pageData} />
				<input type="hidden" name="i__featureImage" value={this.state.selectedUrl} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				
				</form>
								
				<ModalFileBrowser isOpen={this.state.showfileBrowser} atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
					
				</ModalFileBrowser>	
			
			</React.Fragment>
		);
	}
	
}
export default EditEvent;
