import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {LineLoader} from '../../elements';


class PageHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
		
	render(){

		return(
			<div className="rs-breadcrumbs bg7 breadcrumbs-overlay py-0" >
				
					<div className="container pb-50">
						<div className="row">
							<div className="col-md-12 text-center">
								<h1 className="page-title mb-20">{this.props.pageTitle}</h1>
								<ul>
									<li>
										<Link to="/" className="active" >Home</Link>
									</li>
									<li>{this.props.pageTitle}</li>
								</ul>
							</div>
						</div>
					</div>
				
			</div>
		);
	}
	
}
export default PageHeader;
