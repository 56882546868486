import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';

import Menu from './menu';
import AddMenu from './AddMenu';
import EditMenu from './EditMenu';

class MenuList extends Component{
	constructor(props) {
		super(props);
		this.state = {
			showLoader: true,
			showAddMenu: false,
			showEditMenu: false,
			dataList: [],
			dataView: [],
			editData: [],			
		}
		
		this.handleMenuSearch = this.handleMenuSearch.bind(this);
		this.loadAllMenus = this.loadAllMenus.bind(this);
	}
	
	componentDidMount(){
		
		this.loadAllMenus();
	}
	
	loadAllMenus(){
		try{
			
			this.setState({showLoader: true, dataList: [], dataView: []});
			API.get('v1/webadmin/menus').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	handleMenuSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.title).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete the Menu?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			API.get('v1/webadmin/menus/remove/'+item.id).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item){
		this.setState({editData: item, showEditMenu: true});
	}
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				<Menu />
				
				<Row>
					<Col md={9} >
						<div className="input-group" >
							<input type="text" className="form-control form-control-sm" placeholder="Search by Title..." onChange={this.handleMenuSearch} />
							<div className="input-group-append">
								<span className="input-group-text"><i className="ion-android-search" /></span>
							</div>
						</div>
					</Col>
					<Col md={3} >
						<div className="text-right" >
							<ButtonGroup >
								<Button type="button" size="sm"  variant="outline-secondary" onClick={()=>{this.setState({showAddMenu: true})}} >
									<span className="ion-android-add"></span> Add Menu
								</Button>
								
								<Button variant="outline-secondary" size="sm" onClick={this.loadAllMenus} >
									<i className="ion-ios7-reload" /> Refresh
								</Button>
							</ButtonGroup >
						</div>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						<Table hover bordered size="sm cl__table__xs" >
							<thead>
								<tr>
									<th width="80" >S.No</th>
									<th>Menu Title</th>
									<th>URL</th>
									<th width="120" >Manage</th>
								</tr>
							</thead>
							{this.state.showLoader ? 
								<tbody><tr><td colSpan="4" align="center" > <Loader type="dot" /></td></tr></tbody> : 
								<tbody>
									{this.state.dataView.map((item,i)=>{
										return <tr key={i} className={item.activestatus=='0' ? 'bg-red-light-5' : ''} >
											<td>{sno++}</td>
											<td>{item.title}</td>
											<td>
											{item.menulink}
											</td>
											{item.editable!='1' ? <td></td> :
											<td align="center" >
												<Button type="button" size="sm" color="info" onClick={this.handleEdit.bind(this,item)}  >
													<span className="ion-edit"></span>
												</Button>
												&nbsp;
												<Button type="button" size="sm" variant="danger" onClick={this.handleRemove.bind(this,item,i)}  >
													<span className="ion-close"></span>
												</Button>
											</td>
											}
										</tr>;
									})													
									}
								</tbody>
							}
						</Table>
					</Col>
				</Row>
				
				<Modal show={this.state.showAddMenu} onHide={()=>{this.setState({showAddMenu:false})}} backdrop="static" size="md" className="" >
					<Modal.Header closeButton>
						Add Menu
					</Modal.Header>
					<Modal.Body>
						<AddMenu atClose={()=>{this.setState({showAddMenu: false})}} atFinish={this.loadAllMenus} />
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditMenu} onHide={()=>{this.setState({showEditMenu:false})}} backdrop="static" size="md" className="" >
					<Modal.Header closeButton>
						Edit Menu
					</Modal.Header>
					<Modal.Body>
						<EditMenu atClose={()=>{this.setState({showEditMenu: false})}} editData={this.state.editData} atFinish={this.loadAllMenus}  />
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default MenuList;
