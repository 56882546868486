import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {ToastsStore} from 'react-toasts';
import {PageLoader} from '../../elements';
import {getls, getlsItem, setls, setlsItem} from '../../../utils';
import API from '../../../utils/API';
import {classList, Api} from '../../../utils/Variables';

import ChangePassword from './ChangePassword';

class Dashboard extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showPasswordModal: false,
			dataList: [],
			dataView: [],
		}
		
		this.loadApplications = this.loadApplications.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
	}
	

	componentDidMount(){
		
		this.loadApplications();
	}
	
	loadApplications(){
		try{
			this.setState({dataList: [], dataView: [], showLoader: true});
			
			API.post('/v1/web/recent-applications').then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader: false});
				}
				else{
					//ToastsStore.error(res['data'].message);
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleApplictionDownloda(no){
		try{
			this.setState({showLoader: true});
			var filePath = Api.Url +'v1/pdfapp/'+no;
			var a = document.createElement('A');
			a.href = filePath;
			a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a); 
			
			 /* var form = document.createElement("form");
			var element1 = document.createElement("input"); 
			var element2 = document.createElement("input");  

			form.method = "post";
			form.action = filePath;   
			form.target="_blank";

			element1.value=no;
			element1.name="i__id";
			form.appendChild(element1);  

			document.body.appendChild(form);
			
			form.submit(); */
			
			this.setState({showLoader: false});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleLogOut(){
		
		if(!window.confirm('Do you want to logout?')){
			return;
		}
		setlsItem('admission_user_logged', 'no');
		setls('logged_user', '');
		this.setState({isRedirect:true});
		window.location.href = '';
	}
	
	
	render(){
		
		if(getlsItem('admission_user_logged')!='YES'){
			return(<Redirect to="/admission/login" />);
		}
		
		return(
			<React.Fragment>
			
				<PageLoader loading={this.state.showLoader} />
				
				<Container className="pt-20"  >
					<Row>
						<Col md={{span: 10, offset:1 }} >
							
							<Row>
								<Col md={8} >
										
									<Card className="mb-20" >
										<Card.Header>
											<h5 className="text-center" >Welcome, {getls('admission_user', 'name')}</h5>
										</Card.Header>
										<Card.Body>
											
											<ul className="list-unstyled">
												<li className="border-bottom pb-10 mb-10">
													<Link to="/admission/new-application" >
														<span className="pull-right"> 
															Go Next &nbsp; <i className="ion-arrow-right-c" /> 
														</span>
														Click here to post new application
													</Link>
												</li>
												<li className="b-t p-a-1">
													<Link to="/admission/filled-applications" >
														<span className="pull-right"> 
															View All &nbsp; <i className="ion-arrow-right-c" /> 
														</span>
														Click here to view filled application
													</Link>
												</li>
											</ul>
											
										</Card.Body>
									</Card>
							
								</Col>
								
								<Col md={4} >
									<Card>
										<Card.Header>
											<h5 className="text-center" >Menu</h5>
										</Card.Header>
										<Card.Body>
											<ul className="list-unstyled">
												 <li className="border-bottom pb-10 mb-10">
													<Link to="/admission/new-application" >
														<span className="pull-left"> 
															<i className="ion-arrow-right-c" />  &nbsp;
														</span>
														New Applications
													</Link>
												</li>
												<li className="border-bottom pb-10 mb-10">
													<Link to="/admission/filled-applications" >
														<span className="pull-left"> 
															<i className="ion-arrow-right-c" />  &nbsp;
														</span>
														Filled Applications
													</Link>
												</li>
												<li className="border-bottom pb-10 mb-10">
													<a href="javascript:;"  onClick={()=>{this.setState({showPasswordModal: true})}} >
														<span className="pull-left"> 
															<i className="ion-arrow-right-c" />  &nbsp;
														</span>
														Change Password
													</a>
												</li>
												<li className="border-bottom pb-10 mb-10">
													<a href="javascript:;"  onClick={this.handleLogOut} >
														<span className="pull-left"> 
															<i className="ion-arrow-right-c" />  &nbsp;
														</span>
														Logout
													</a>
												</li>
											</ul>
										</Card.Body>
									</Card>
									
									<Card className="mt-20" >
										<Card.Header>
											<h5 className="text-center" >Recent Applications</h5>
										</Card.Header>
										<Card.Body>
											
											<ul className="list-unstyled">
											{this.state.dataView.map((item,i)=>{
												return <li className="border-bottom pb-10 mb-10">
													<a href="javascript:;" onClick={this.handleApplictionDownloda.bind(this,item.id)} >
														<span className="pull-right"> 
															Download &nbsp; <i className="ion-arrow-down-c" /> 
														</span>
														{item.application_no}
													</a>
												</li>;
											})
											}
											</ul>
										</Card.Body>
									</Card>
								</Col>
										
							</Row>
							
						</Col>
					</Row>
				</Container>
				
				<Modal show={this.state.showPasswordModal} onHide={()=>{this.setState({showPasswordModal: false})}} backdrop="static" >
					<Modal.Header closeButton >
						Change Password
					</Modal.Header>
					<Modal.Body>
						<ChangePassword />
					</Modal.Body>
				</Modal>
			
			</React.Fragment>
		);
	}
	
}
export default Dashboard;
