import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, Form } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import {Api, classList} from '../../../utils/Variables';
import API from '../../../utils/API';

import ModalFileBrowser from '../Filemanager/ModalFileBrowser';

class AdmissionEntry extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddModal: false,
			showfileBrowser: false,
			btnText: 'Update',
			topWrapper: [],
			iconWrapper: [],
			sideWrapper: [],
			wrapperType: '',
		}
		
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFomrSubmit = this.handleFomrSubmit.bind(this);
	}
	
	componentDidMount(){
		
		//this.setDefaultWrappers();
	}
	
	setDefaultWrappers(){
		try{
			
			this.setState({
				iconWrapper: {
					selectedUrl: this.getFieldValue('home_page_wrapper')
				},
				topWrapper: {
					selectedUrl: this.getFieldValue('header')
				},
				sideWrapper: {
					selectedUrl: this.getFieldValue('sidewrapper')
				}
			});
		}
		catch(error){ }
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/")
			
			console.log(files);
			
			var d ={
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			};
			
			
			if(this.state.wrapperType=='top'){
				this.setState({topWrapper: d});
			}
			else if(this.state.wrapperType=='icon'){
				this.setState({iconWrapper: d});
			}
			else {
				this.setState({sideWrapper: d});
			}
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	

	handleFomrSubmit(e){
		e.preventDefault();
		try{
			this.setState({btnText: 'Updating..'});
			
			API.post('v1/webadmin/onlineadmission/admission/update', $("#frmAddAdmission").serialize()).then(res=>{
				if(res['data'].status=='1'){
					//document.getElementById("frmAddAdmission").reset();
					this.setState({iconWrapper: [], btnText: 'Update'});
					notify.show(res['data'].message, 'success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({btnText: 'Update'});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({btnText: 'Update'});
		}
	}
	
	handleFiles(e){
		//console.log(e.target.files);
		let file = e.target.files;
		this.setState({selectedFile: file});
	}
	
	getImage(imgType, withUrl=false){
		try{
			var d = [];
			if(imgType=='top'){
				d = this.state.topWrapper;
			}
			else if(imgType=='icon'){
				d = this.state.iconWrapper;
			}
			else {
				d = this.state.sideWrapper;
			}
			
			if(Object.keys(d).length>0 && d.selectedUrl!='' && d.selectedUrl!=null){
				
				return (withUrl) ? Api.ImageUrl+d.selectedUrl : d.selectedUrl;
			}
			return false;
		}
		catch(error){
			return false;
		}
	}
	
	
	getFieldValue(fieldName){
		try{
			
			var d = this.props.data;
			return d[fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	render(){

		return(
		<>
		
			<form action="" method="post" id="frmAddAdmission" encType="multipart/form-data" onSubmit={this.handleFomrSubmit} >
			
				<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
				<input type="hidden" name="i__applicationId" value={this.getFieldValue('application_id')} />
				
				<Row>
					<Col md={5} >
						<label>Admission No <span className="text-danger">*</span></label>
					</Col>
					<Col md={7} >
						<input type="text" name="i__admissionNo" className="form-control form-control-sm" required placeholder="" defaultValue={this.getFieldValue('admitted_no')} />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={5} >
						<label>Admission Date <span className="text-danger">*</span></label>
					</Col>
					<Col md={7} >
						<input type="date" name="i__admissionDate" className="form-control form-control-sm" required placeholder="" defaultValue={this.getFieldValue('admitted_on')} />
					</Col>
				</Row>
					
				<Row className="mt-15" >
					<Col md={5} >
						<label>Admission Class <span className="text-danger">*</span></label>
					</Col>
					<Col md={7} >
						<select name="i__classFor" className="form-control form-control-sm"  required >
							<option value=""> -Select- </option>
							{classList.map((item,i)=>{
								return <option key={i} value={item.key} selected={this.getFieldValue('admitted_to')==item.key ? 'selected' : ''} > {item.name} </option>;
							})
							}
						</select>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={5} >
						<label>TC Attached <span className="text-danger">*</span></label>
					</Col>
					<Col md={7} >
						<Form.Check
							custom
							inline
							label="Yes"
							type="radio"
							id="tc-1"
							name="i__tcAttached"
							value="1"
							defaultChecked={this.getFieldValue('tc_attached')=='1'}
						  />
						  <Form.Check
							custom
							inline
							label="No"
							type="radio"
							id="tc-2"
							name="i__tcAttached"
							value="0"
							defaultChecked={this.getFieldValue('tc_attached')=='0'}
						  />
					</Col>
				</Row>
				
				
				<Row className="mt-15" >
					<Col md={5} >
						<label>Birth Cretificate Attached <span className="text-danger">*</span></label>
					</Col>
					<Col md={7} >
						<Form.Check
							custom
							inline
							label="Yes"
							type="radio"
							id="bc-1"
							name="i__bcAttached"
							value="1"
							defaultChecked={this.getFieldValue('bc_attached')=='1'}
						  />
						  <Form.Check
							custom
							inline
							label="No"
							type="radio"
							id="bc-2"
							name="i__bcAttached"
							value="0"
							defaultChecked={this.getFieldValue('bc_attached')=='0'}
						  />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={5} >
						<label>Remarks <span className="text-danger">*</span></label>
					</Col>
					<Col md={7} >
						<textarea name="i__remarks" className="form-control form-control-sm" rows="5" defaultValue={this.getFieldValue('admitted_remarks')} ></textarea>
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						<hr />
						<div className="text-center" >
							<Button type="submit" variant="success" size="sm" disabled={this.state.btnText!='Update'} >
								{this.state.showLoader ? <i className="ion-loading-c" /> : <i className="ion-checkmark" />}
								&nbsp; {this.state.btnText}
							</Button>
						</div>
					</Col>
				</Row>
			</form>
			
			
			<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</>
		);
	}
	
}
export default AdmissionEntry;
