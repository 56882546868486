import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import NOIMAGE_WIDE from '../../../../assets/img/noimage-xxl.png';
import Select from 'react-select';
import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';

class EditSlider extends Component{
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update',
			showLoader: false,
			linkList: [],
			selectedImage: [],
			selectedFilename: '',
			selectedUrl: '',
			editData: this.props.editData,
		}
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.setState({
			selectedUrl: this.props.editData['url']
		});
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	setField(fieldName, e){
		try{
			
			var d = this.state.editData;
			d[fieldName] = e.target.value;
			this.setState({editData: d});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/webadmin/slider/update', $("#frm_editSlider").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnSave: 'Update'});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Update'});
				}
			});
		}
		catch(error){
			alert('Unexpected Error');
			this.setState({showLoader: false, btnSave: 'Update'});
		}
	}
	

	
	
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_editSlider" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__id" value={this.getField('id')} />
					<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
					
					<Row>
						<Col md={12} >
							<label>Slider Image <span className="required"> (1920 x 800)</span></label>
							
							{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length > 4 ? 
								<div>
									<img src={Api.ImageUrl+this.state.selectedUrl} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}} />
								</div>
							:
								<div>
									
									<img src={NOIMAGE_WIDE} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}}  />
								</div>
							}
							
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={10} >
							<label>Slider Title <span className="text-danger"></span></label>
							<input type="text" name="i__title" className="form-control form-control-sm"  placeholder="Slider Title" value={this.getField('title')} onChange={this.setField.bind(this,'title')} />
						</Col>
						<Col md={2} >
							<label>Slider Order <span className="text-danger"></span></label>
							<input type="number" name="i__order" className="form-control form-control-sm"  placeholder="Order" value={this.getField('slideorder')} onChange={this.setField.bind(this,'slideorder')} />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>Slider Content <span className="text-danger"></span></label>
							<textarea name="i__content" className="form-control form-control-sm"  placeholder="Slider Content" rows="3" value={this.getField('content')} onChange={this.setField.bind(this,'content')} ></textarea>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<hr />
							<div className="float-left" >
								<label className="chk-container">Active
								  <input type="checkbox" name="i__active" defaultChecked={this.getField('activestatus')=='1'} />
								  <span className="checkmark"></span>
								</label>
							</div>
							<div className="float-right" >
								<Button type="reset" variant="danger" size="sm" onClick={this.props.atClose} >
									Cancel 
								</Button>
								&nbsp;
								<Button type="submit" variant="success" size="sm" disabled={this.state.btnSave!='Update'} >
									{this.state.btnSave=='Update' ? 
									<span className="ion-checkmark"></span> : 
									<span className="ion-loading-a"></span>}
									&nbsp; {this.state.btnSave}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
				
				<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
				
			</React.Fragment>
		);
	}
	
}
export default EditSlider;
