import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Container, Row,Col, Card, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {ToastsStore} from 'react-toasts';
import {getls, setlsItem, setls, getlsItem} from '../../../utils';
import API from '../../../utils/API';
import LOGO from '../../../assets/img/Sri-Vaiyapuri-Vidyala-logo.png';

class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
		}
		
		this.doLogin = this.doLogin.bind(this);
	}
	
	doLogin(e){
		e.preventDefault();
		try{
			this.setState({btnText: 'Validation...', showLoader: true});
			API.post('v1/web/login', $("#frmLogin").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					setlsItem('admission_user_logged', 'YES');
					setlsItem('apikey', res['data'].data.apikey);
					setls('admission_user', res['data'].data);
					this.setState({isRedirect: true});
					window.location.href='';
				}
				else{
					ToastsStore.error(res['data'].message);
					this.setState({btnText: 'Login', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Login', showLoader: false});
		}
	}
	
	render(){

		if(getlsItem('admission_user_logged')=='YES'){
			return(<Redirect to="/admission/home" />);
		}
		
		
		return(
			<React.Fragment>
			
				<Container className="pt-20"  >
					<Row>
						<Col md={{ span:6, offset: 3}} >
							
							<Card>
								<Card.Body>
									<Row>
										<Col md={12} >
											<form action="" method="post" id="frmLogin" encType="multipart/form-data" onSubmit={this.doLogin} >
											
											<h4 className="text-center" >Applicant Login</h4>
											<hr />
											
											<p>Please enter your application number and register mobile number to login</p>
											
											<Row>
												<Col md={12} >
													<label>Mobile No</label>
													<input type="number" className="form-control form-control-sm" required name="i__username" placeholder="Your registered Mobile No" />
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={12} >
													<label>Password</label>
													<input type="password" className="form-control form-control-sm" required name="i__password" placeholder="Your Password" />
												</Col>
												<Col md={12} >
													<div className="pull-right mt-10" >
														<Link to="/admission/forgot-password" >Forgot Password?</Link>
													</div>
												</Col>
												<Col md={12} > <hr /> </Col>
											</Row>
											
											<Row className="mt-1" >
												<Col md={12} >
													<div className="text-right" >
														<Button type="submit" size="md" variant="primary" block disabled={this.state.btnText!='Login'} >
															{this.state.btnText}
														</Button>
													</div>
												</Col>
												<Col md={12} > <hr /> </Col>
											</Row>
											
											<Row>
												<Col md={12} >
													<Link to="/admission/online-registration"  className="btn btn-info btn-block btn-md" >
														New Application Registration
													</Link>
												</Col>
											</Row>
											
											</form>
											
										</Col>
										
										{/*<Col md={6} >
											<h4>Online registration</h4>
											<p>Please click the below link to register your child profile</p>
											
										</Col>*/}
										
										
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			
			</React.Fragment>
		);
	}
	
}
export default Login;
