import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table, Card } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';
import NO_IMAGE from '../../../../assets/img/noimage-square.png';

class Settings extends Component{
	constructor(props) {
		super(props);
		this.state = {
		  showLoader: false,
		  showFileBrowser: false,
		  logoPath: [],
		  favPath: [],
		  btnSubmit: 'Update',
		  data: [],
		  imgType: '',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
	}
	
	componentDidMount(){
	
		this.loadSettings();
	
	}
	
	handleFileBrowserClick(files){
		try{
			var d = [];
			var item = files[0];
				var url = item.data.path +'/'+ item.data.name;
				url= url.replace(/\/\//g, "/");
				d.push({
					url: Api.ImageUrl+ url,
					filepath: url,
					name: item.data.name				
				});
			
			if(this.state.imgType=='logo')
				this.setState({logoPath: Api.ImageUrl+ url});
			else if(this.state.imgType=='fav')
				this.setState({favPath: Api.ImageUrl+ url});
			
		}
		catch(error){ console.log(error); }
	}
	
	loadSettings(){
		try{
			
			this.setState({showLoader: true});
			var cid = getls('company','id');
			API.get('v1/webadmin/settings/me/'+cid).then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					this.setState({data: res['data'].data, logoPath: Api.ImageUrl+'/'+d.logo, favPath: Api.ImageUrl+'/'+d.favicon, showLoader: false});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnSubmit: 'Updating..'});
			API.post('v1/webadmin/settings/me/update',$("#frm_Settings").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, btnSubmit: 'Update'});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSubmit: 'Update'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnSubmit: 'Update'});
			console.log(error);
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.data;
			return d[fieldName];
		}
		catch(error){
			console.log(error);
			return '';
		}
	}
	
	setField(fieldName, e){
		try{
			
			var d = this.state.data;
			d[fieldName] = e.target.value;
			this.setState({data: d});
		}
		catch(error){
			console.log(error);
			return '';
		}
	}
	
	render(){

		let sno = 1;
	
	var IMG = NO_IMAGE;
	var FAV = NO_IMAGE;
	if(this.state.logoPath.length>0)
		IMG = this.state.logoPath;
	
	if(this.state.favPath.length>0)
		FAV = this.state.favPath;
		
		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				<form action="" method="post" encType="multipart/form-data" id="frm_Settings" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="i__id" value={getls('company','id')} />				
				<input type="hidden" name="i__logoPath" value={this.state.logoPath} />
				<input type="hidden" name="i__favPath" value={this.state.favPath} />
					
					<Row>
						<Col md={8} >
							
							<Card>
								<Card.Header>
									General Details
								</Card.Header>
								<Card.Body>
									<Row>
										<Col md={3} ><label>Site Title <span className="text-danger">*</span></label></Col>
										<Col md={9} >
											<input type="text" className="form-control form-control-sm"   name="i__title" required value={this.getField('sitetitle')} onChange={this.setField.bind(this,'sitetitle')} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={3} ><label>Site Meta Content<span className="required"></span></label></Col>
										<Col md={9} >
											<textarea className="form-control form-control-sm"   name="i__meta" required rows="3" value={this.getField('sitemeta')} onChange={this.setField.bind(this,'sitemeta')} ></textarea>
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={3} ><label>Site URL <span className="required"></span></label></Col>
										<Col md={9} >
											<input type="url" className="form-control form-control-sm"   name="i__url" value={this.getField('siteurl')} onChange={this.setField.bind(this,'siteurl')}  />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={3} ><label>Address <span className="required"></span></label></Col>
										<Col md={9} >
											<input type="text" className="form-control form-control-sm"   name="i__address" value={this.getField('address')} onChange={this.setField.bind(this,'address')}  />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={3} ><label>City & State<span className="required"></span></label></Col>
										<Col md={6} >
											<input type="text" className="form-control form-control-sm"   name="i__city" placeholder="City" value={this.getField('city')} onChange={this.setField.bind(this,'city')} />
										</Col>
										<Col md={3} >
											<input type="text" className="form-control form-control-sm"   name="i__state" placeholder="State" value={this.getField('state')} onChange={this.setField.bind(this,'state')} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={3} ><label>Country & Pincode<span className="required"></span></label></Col>
										<Col md={6} >
											<input type="text" className="form-control form-control-sm"   name="i__country" placeholder="Country" value={this.getField('country')} onChange={this.setField.bind(this,'country')} />
										</Col>
										<Col md={3} >
											<input type="number" className="form-control form-control-sm"   name="i__pincode" placeholder="Pincode" value={this.getField('pincode')} onChange={this.setField.bind(this,'pincode')} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={3} ><label>Mobile & Phone<span className="required"></span></label></Col>
										<Col md={5} >
											<input type="text" className="form-control form-control-sm"   name="i__mobile" placeholder="Mobile" value={this.getField('mobile')} onChange={this.setField.bind(this,'mobile')} />
										</Col>
										<Col md={4} >
											<input type="text" className="form-control form-control-sm"   name="i__phone" placeholder="Phone" value={this.getField('phone')} onChange={this.setField.bind(this,'phone')} />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={3} ><label>Email & Fax<span className="required"></span></label></Col>
										<Col md={5} >
											<input type="text" className="form-control form-control-sm"   name="i__email" placeholder="Email Id" value={this.getField('email')} onChange={this.setField.bind(this,'email')} />
										</Col>
										<Col md={4} >
											<input type="text" className="form-control form-control-sm"   name="i__fax" placeholder="Fax" value={this.getField('fax')} onChange={this.setField.bind(this,'fax')} />
										</Col>
									</Row>
								</Card.Body>
							</Card>
							
						</Col>
						
						
						<Col md={4} >
							<Card>
								<Card.Header>
									Social Settings
								</Card.Header>
								<Card.Body >
									
									<Row>
										<Col md={3} ><label>Facebook <span className="required"></span></label></Col>
										<Col md={9} >
											<input type="url" className="form-control form-control-sm"   name="i__facebook" placeholder="http://fb.me/your-fb-id" value={this.getField('facebook')} onChange={this.setField.bind(this,'facebook')} />
										</Col>
									</Row>
									
									<Row className="mt-10" >
										<Col md={3} ><label>Twitter <span className="required"></span></label></Col>
										<Col md={9} >
											<input type="url" className="form-control form-control-sm"   name="i__twitter"  placeholder="http://twitter.com/your-twitter-id" value={this.getField('twitter')} onChange={this.setField.bind(this,'twitter')} />
										</Col>
									</Row>
									
									<Row className="mt-10" >
										<Col md={3} ><label>Youtube <span className="required"></span></label></Col>
										<Col md={9} >
											<input type="url" className="form-control form-control-sm"   name="i__youtube"  placeholder="https://youtube.com" value={this.getField('youtube')} onChange={this.setField.bind(this,'youtube')} />
										</Col>
									</Row>
									
									<Row className="mt-10" >
										<Col md={3} ><label>G+<span className="required"></span></label></Col>
										<Col md={9} >
											<input type="url" className="form-control form-control-sm"   name="i__gplus"  placeholder="https://plus.google.com" value={this.getField('gplus')} onChange={this.setField.bind(this,'gplus')} />
										</Col>
									</Row>
									
									<Row className="mt-10" >
										<Col md={3} ><label>Whatsapp<span className="required"></span></label></Col>
										<Col md={9} >
											<input type="text" className="form-control form-control-sm"   name="i__whatsapp"  placeholder="+91 9876543210" value={this.getField('whatsapp')} onChange={this.setField.bind(this,'whatsapp')} />
										</Col>
									</Row>
									
									<Row className="mt-10" >
										<Col md={3}><label>Fav Icon</label></Col>
										<Col md={9} >
											<img src={FAV} style={{cursor:'pointer', maxHeight:'80px',}} onClick={(e)=>{this.setState({imgType: 'fav', showFileBrowser: true})}} />
										</Col>
									</Row>
									
									<Row className="mt-10" >
										<Col md={3}><label>Logo</label></Col>
										<Col md={9} >
											<img src={IMG} style={{cursor:'pointer', maxHeight:'95px',}} onClick={(e)=>{this.setState({imgType: 'logo', showFileBrowser: true})}} />
										</Col>
									</Row>
									
									
									
									<Row className="mt-10" >
										<Col md={12} >
											<hr />
											<div className="text-center" >
												
												<Button type="submit" className="form-control form-control-sm"  color="success" disabled={this.state.btnSubmit!='Update'} >
													{this.state.btnSubmit=='Update' ? 
														<span className="ion-android-checkmark" ></span> :
														<span className="ion-loading-a" ></span>} &nbsp;
													{this.state.btnSubmit}
												</Button>
											</div>
										</Col>
									</Row>
									
								</Card.Body>
							</Card>
						</Col>
						
						
					</Row>
				
				</form>
				
				<ModalFileBrowser isOpen={this.state.showFileBrowser} imageOnly   
					atClose={()=>{this.setState({showFileBrowser: !this.state.showFileBrowser})}}
					fileBrowserClick={this.handleFileBrowserClick} >
						
				</ModalFileBrowser>
				
			</React.Fragment>
		);
	}
	
}
export default Settings;
