import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table, Card } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, upperCase} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import API from '../../../../utils/API';
import {PageLoader, LineLoader, Loader, NiCollapse} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';
import NOIMAGE_WIDE from '../../../../assets/img/noimage-xxl.png';


import ContentEditor from './ContentEditor';
import HomePageMenu from './HomePageMenu';
import AddStaffImage from './AddStaffImage';
import EditStaffImage from './EditStaffImage';

class HomePageTeachers extends Component{
	constructor(props) {
		super(props);
		this.state = {
		  showAddSlider: false,
		  showEditSlider: false,
		  showTextHolder: false,
		  editData: [],
		  dataList: [],
		  dataView: [],
		  btnText: 'Update',
		  btnMission: 'Update',
		  selectedImage: [],
			selectedFilename: '',
			selectedUrl: '',
			showfileBrowser: false,
		}
		
		this.loadData = this.loadData.bind(this);
		
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true, dataList:[], dataView: []});
			var form = new FormData();
			form.append('i__dataKey', 'home_teachers_list');
			API.post('v1/webadmin/staticdata/list', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, dataView: res['data'].data, selectedUrl: res['data'].data.data_value, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handlePageSearch(e){
		try{
			
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return(upperCase(obj.title).indexOf(v)>-1);
			});
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemove(item,index){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to delete?',
			buttons: {
				Yes: function(){
					$this.confirmRemove(item,index);
				},
				No: function(){
					
				}
			}
		});
	}
	
	confirmRemove(item,i){
		try{
			
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__id', item.id);
			form.append('i__dataKey', 'home_teachers_list');
			API.post('v1/webadmin/staticdata/remove/', form).then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.state.dataList;
					var index = d.findIndex(function(obj){
						return(obj.id==item.id);
					});
					d.splice(index,1);
					notify.show(res['data'].message, 'success');
					this.setState({showLoader: false, dataList: d,dataView: d});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
			alert('Unexpected Error');
		}
	}
	
	handleEdit(item,e){
		try{
			
			this.setState({
				editData: item,
				showEditModal: true
			});
		}
		catch(error){
			
		}
	}
		
	render(){

		let sno=1;
		
		return(
			<React.Fragment>
			
				<HomePageMenu />
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				<NiCollapse title="Header Text & About Content" className="mb-20" >
					<Row>
						<Col md={4} >
							<label>Header Text</label>
							<ContentEditor dataKey="home_teachers_heading" rowSize="1" />
						</Col>
						<Col md={8} >
							<label>About Text</label>
							<ContentEditor dataKey="home_teachers_about" rowSize="1" />
						</Col>
					</Row>
				</NiCollapse>
				
				<Row>
					<Col md={8} >
						<label>Home page Teachers List</label>
					</Col>
					<Col md={4} >
						<div className="pull-right" >
							<ButtonGroup >
								<Button variant="outline-secondary" size="sm" onClick={()=>{this.setState({showAddModal: true})}} >
									<i className="ion-plus" /> Add Staff
								</Button>
								<Button variant="outline-secondary" size="sm" onClick={this.loadData} >
									<i className="ion-refresh" /> Refresh
								</Button>
							</ButtonGroup >
						</div>
					</Col>
					<Col md={12}><hr /></Col>
				</Row>
				
				<Row>
					<Col md={12} >
						{this.state.showLoader ? <center><Loader type="dot" /></center> : 
							<Table hover bordered size="sm cl__table__xs" >
								<thead>
									<tr>
										<th width="80" >S.No</th>
										<th>Image</th>
										<th>Name</th>
										<th>Designation</th>
										<th width="100" >Order</th>
										<th width="120" >Action</th>
									</tr>
								</thead>
								<tbody>
								{this.state.dataView.map((item,i)=>{
									return <tr key={i} className={item.active_status!='1' ? 'bg-red-light-5' : ''} >
										<td>{sno++}</td>
										<td>
											<img src={Api.ImageUrl+item.data_value} style={{maxWidth: '150px'}} />											
										</td>
										<td>{item.data_1}</td>
										<td>{item.data_2}</td>
										<td align="center">{item.data_4}</td>
										<td align="center" >
											
											<Button type="button" size="sm" color="primary" title="Edit" onClick={this.handleEdit.bind(this,item)} >
												<span className="ion-edit"></span>
											</Button>
											&nbsp;
											<Button type="button" size="sm" variant="danger" title="Remove" onClick={this.handleRemove.bind(this,item,i)} >
												<span className="ion-close-round"></span>
											</Button>
										</td>
									</tr>;
								})
								}
								</tbody>
							</Table>
						}
					</Col>
				</Row>
				
				<Modal show={this.state.showAddModal} onHide={()=>{this.setState({showAddModal:false})}} backdrop="static" size="lg" className="" >
					<Modal.Header closeButton>
						Add Staff
					</Modal.Header>
					<Modal.Body>
						<AddStaffImage atFinish={this.loadData} />	
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditModal} onHide={()=>{this.setState({showEditModal:false})}} backdrop="static" size="lg" className="" >
					<Modal.Header closeButton>
						EditStaff
					</Modal.Header>
					<Modal.Body>
						<EditStaffImage editData={this.state.editData} atFinish={this.loadData} />	
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default HomePageTeachers	;
