import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import {Var} from '../utils/Variables';
import SiteLayout from '../layouts/SiteLayout';

import AdminLayout from '../layouts/AdminLayout';
import Admin_Login from '../admin/pages/Login';

import AdmissionLayout from '../layouts/AdmissionLayout';

import NotFound from '../views/pages/NotFound';

export default () => (
	
<BrowserRouter >
	<Switch>

		<Route path={"/"+Var.AdminPath+"/app"} component={AdminLayout} />	
		<Route path={"/"+Var.AdminPath} component={Admin_Login} />		
		
		<Route path="/admission" component={AdmissionLayout} />
		
		<Route path="/" component={SiteLayout} />
		
	</Switch>
</BrowserRouter >
);