import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import $ from "jquery";
import {Nav, NavItem} from 'react-bootstrap';
import {Var} from '../../../../utils/Variables';

class HomePageMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  
		}
	}
	
	
	render(){
	
	  return (
		<div className="page-sub-nav ">
		  <Nav pills>
			<NavItem>
				<NavLink to={"/"+Var.AdminPath+"/app/cms/static/homepage"} exact >
					Home Page Content
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to={"/"+Var.AdminPath+"/app/cms/static/homepage/teachers"}  exact >
					Our Teachers
				</NavLink>
			</NavItem>	
			<NavItem>
				<NavLink to={"/"+Var.AdminPath+"/app/cms/static/homepage/welcomebanner"}  exact >
					Welcome Banner
				</NavLink>
			</NavItem>	
		  </Nav>
		</div>
	  );
	}

}
export default HomePageMenu;
