import React, { Component, Suspense, lazy } from 'react';

import '../admission/assets/custom.css';

import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import routes from '../admission';
import {Header, SideNav, Footer} from '../admission/elements';
import API from '../utils/API';
import {Api} from '../utils/Variables';
import {setls} from '../utils';
import Notifications, {notify} from 'react-notify-toast';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import nav from '../admission/_nav';

class AdmissionLayout extends Component{
	constructor(props){
		super(props);
		this.state = {
			siteData: [],
		}
	}
	
	componentDidMount(){
		
	}
	
	render(){
			
		return(
			<div className="" > 
				<div className="" >
				
				
				 <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
				<Notifications />
				
				<Header />
				
					<div className="admission-page-content-holder" >
					
						<Switch>
							{routes.map((page,key)=>(
								<Route exact path={page.path}  component={page.component} key={key} />
							))}
						</Switch>	
						
					</div>
					
					<Footer />
				
				</div>				
			</div>
		);
	}
	
}
export default AdmissionLayout;