import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls} from '../../../utils';
import API from '../../../utils/API';
import {LineLoader} from '../../elements';

class Announcements extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		this.loadAnnounceMents();
	}
	
	loadAnnounceMents(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('type','announcement');
			form.append('expired','1');
			API.post('v1/site/news/list',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	marqueeStop(){
		document.getElementById('mmm_an_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_an_scroll_mq').start();
	}
	
	
	loadHistoryData(){
		try{
			this.setState({showLoader: true});
			API.get('v1/site/content/'+this.props.dataKey).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){}
	}
	
	render(){

		return(
			<div>
				<Card className="card-color" >
					<Card.Header>
						Announcements
					</Card.Header>
					<Card.Body>
						<div>
							<marquee id="mmm_an_scroll_mq" direction="up" scrollamount="4" style={{height: '343px'}} onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart}  >
								<ul className="list-style-none" style={{paddingLeft: '0px', cursor: 'pointer'}} >
								{this.state.dataList.map((item,i)=>{
									return <li key={i} style={{listStyle: 'none'}} >
										{/*item.title
										<br />*/}
										<div dangerouslySetInnerHTML={{__html: item.content}} />
										<hr />
									</li>;
								})
								}
								</ul>
							</marquee>
						</div>
					</Card.Body>
				</Card>
			</div>									
		);
	}
	
}
export default Announcements;
