import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {Row,Col, Carousel, Button, Modal, ButtonGroup, Table } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Pagination from "react-js-pagination";
import CKEditor from 'ckeditor4-react';

import {getls, upperCase, momentDate, makeUrl, selfUrlMask} from '../../../../utils';
import API from '../../../../utils/API';
import {Api, Var} from '../../../../utils/Variables';
import {PageLoader, LineLoader, Loader} from '../../../elements';

import ModalFileBrowser from '../../Filemanager/ModalFileBrowser';


const styles = {
	imgCloseBtn: {
		position: 'absolute',
		right: '21px',
		top: '69px',
		background: '#ff2828',
		color: '#fff',
		padding: '0px 3px',
		borderRadius: '100px',
		fontSize: '11px',
		cursor: 'pointer',
	}
}

class EditPage extends Component{
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update Page',
		  showStudentModal: false,
		  showFileManager: false,
		  showEditModal: false,
		  isActive: true,
		  editSlug: false,
		  pageData: '',
		  inTab: false,
		  inFeatureImage: false,
		  selectedFilename: '',
		  selectedUrl: '',
		  pageUrl: '',
		  pid: this.props.match.params.id,
		}
		
		this.handlePageContentChange = this.handlePageContentChange.bind(this);
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);	
		this.removeSelectedFeatureImage = this.removeSelectedFeatureImage.bind(this);	
		this.handleTitleChange = this.handleTitleChange.bind(this);	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);	
		this.handleJoditChange = this.handleJoditChange.bind(this);
	}
	
	componentDidMount(){
	
		try{
			
			this.loadPage();
			var h = document.getElementsByClassName("cl__page_title")[0];
			if(h!=null && h!=undefined)
				h.innerHTML = "Update Page";
		}
		catch(error){
			
		}
	}
	
	loadPage(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/webadmin/page/'+this.state.pid).then(res=>{
				if(res['data'].status=='1')
				{
					var d = res['data'].data;
					this.setState({
						isActive: d.activestatus==1,
						pageUrl: d.pageurl,
						pageData: d.pagecontent,
						inFeatureImage: (d.featureimage!='' && d.featureimage!=null && d.featureimage.length>3) ? true : false,
						selectedFilename: '',
						selectedUrl: d.featureimage!='' && d.featureimage!=null && d.featureimage.length>3 ? d.featureimage : '',
						data: d,
						showLoader: false
					});
					//console.log(this.state.pageData);
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	removeSelectedFeatureImage(){
		this.setState({ selectedFilename: '',selectedUrl: '' });
	}
	
	
	handleTitleChange(e){
		try{
			var str = e.target.value;
			var newString = makeUrl(str); //str.replace(/[^A-Z0-9]/ig, "-");
			this.setState({pageUrl: newString.toLowerCase()});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnSave: 'Updating..', showLoader: true});
			
			API.post('v1/webadmin/page/update/', $("#frmEdit_Page").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update Page', showLoader: false});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update Page', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update Page', showLoader: false});
		}
	}
	
	handleJoditChange(content){
		
		this.setState({pageData: content});
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.data;
			return selfUrlMask(d[fieldName]);
		}
		catch(error){
			
		}
	}
	
	setField(fieldName,e){
		try{
			
			var d = this.state.data;
			d[fieldName] = e.target.valuel
			this.setState({data: d});
		}
		catch(error){
			
		}
	}
	
	render(){

		return(
			<React.Fragment>
			
				<LineLoader loading={this.state.showLoader} />
				<PageLoader loading={false} />
				
				<Row className="border-bottom pb-10 mb-10" >
					<Col md={9} >
						
					</Col>
					<Col md={3} >
						<div className="text-right" >
							<ButtonGroup >
								<Link to={"/"+Var.AdminPath+"/app/cms/pages"} className="btn btn-outline-secondary btn-sm" >
									<span className="ion-ios7-arrow-thin-left"></span> Back to Pages
								</Link>
							</ButtonGroup >
						</div>
					</Col>
					
				</Row>
								
				<form action="" method="post" encType="multipart/form-data" id="frmEdit_Page" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__id" value={this.getField('id')} />
					
					<Row>
						<Col md={9} >
					
							<Row>
								<Col md={12} >
									<label>Title <span className="text-danger">*</span></label>
									<input type="text" name="i__pageTitle" className="form-control form-control-sm" required placeholder="Page Title" onChange={this.handleTitleChange} defaultValue={this.getField('pagetitle')} />
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12} >
									<label>Page URL <span className="text-danger">*</span></label>
									<div className="input-group input-group-sm" >
										<div className="input-group-append">
											<span className="input-group-text">{Api.SiteUrl}</span>
										</div>
										<input type="text" name="i__pageUrl" className="form-control form-control-sm" required placeholder="Page URL" readOnly={!this.state.editSlug} value={this.state.pageUrl} />
										<div className="input-group-append">
											<span className="input-group-text">
												<a href="javascript:;" onClick={()=>{this.setState({editSlug: !this.state.editSlug})}} >
													<span className="ion-edit"></span>
												</a>
											</span>
										</div>										
									</div>
								</Col>
							</Row>
							
							<Row className="mt-20" >
								<Col md={12} >
									<label>Page Content <span className="text-danger">*</span>
										<div style={{position: 'absolute',top: '-1px',left: '150px'}} >											
											<Button variant="info"size="sm" onClick={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
												Open Filemanager
											</Button>
										</div>
									</label>
									
									<div className="page-content-editor" >
									{!this.state.showLoader ? <CKEditor
											data={this.getField('pagecontent')}
											config={{
												height: '500px',
											}}
											onChange={this.onContentEditorChange}
									/> : null}
									</div>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12} >
									<label>Meta Title</label>
									<textarea name="i__metaTitle" className="form-control form-control-sm"  rows="2" placeholder="Meta Title" value={this.getField('metatitle')} onChange={this.setField.bind(this,'metatitle')} ></textarea>
								</Col>
							</Row>
							<Row className="mt-10" >
								<Col md={12} >
									<label>Meta Description</label>
									<textarea type="textarea" name="i__metaDescription" className="form-control form-control-sm" rows="3" placeholder="Meta Description" value={this.getField('metadescription')} onChange={this.setField.bind(this,'metadescription')}  ></textarea>
								</Col>
							</Row>
							
						</Col>
						
						<Col md={3} >
							
							<Row>
								<Col md={12} >
									<label>Page Template</label>
									<select className="form-control form-control-sm" name="i__pageTemplate" >
										<option value="withsidebar" > Page With Sidebar </option>
										<option value="content"> Content Only </option>
									</select>
								</Col>
							</Row>
							
							<Row className="mt-20" >
								<Col md={12} >
									<div className="float-left" >
										<label className="chk-container">Active Status
										  <input type="checkbox" name="i__active" value="1" defaultChecked={true} />
										  <span className="checkmark"></span>
										</label>
									</div>
									<div className="float-right" >
										<Button type="submit" size="sm" variant="primary" >
											{this.state.btnSave=='Update Page' ? 
												<span className="ion-android-checkmark" ></span> :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.btnSave}
										</Button>
									</div>
									
								</Col>
							</Row>
							
						</Col>
						
					</Row>
					
					<input type="hidden" name="i__pageData" value={this.state.pageData} />
				<input type="hidden" name="i__featureImage" value={this.state.selectedUrl} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				
				</form>
								
				<ModalFileBrowser isOpen={this.state.showfileBrowser} atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
					
				</ModalFileBrowser>	
			
			</React.Fragment>
		);
	}
	
}
export default EditPage;
